<template>
    <div class="quiz" :class="{'quiz_activate': activate}">
        <transition name="opacity" mode="out-in">
            <div class="quiz__question" v-if="activate">
                <div class="quiz__header">
                    <v-close class="quiz__close" @click="closeHandler"></v-close>
                    <!--                    <div class="quiz__title">{{name}}</div>-->
                    <!--                    <div class="quiz__steps"><span>{{step}}</span> / {{steps}}</div>-->
                </div>

                <div class="quiz__content">
                    <transition name="opacity" mode="out-in">
                        <div v-if="!result && question" class="quiz__questions">
                            <transition name="opacity" mode="out-in">
                                <v-question
                                    :title="question.question"
                                    :answers="question.answer"
                                    :id="step"
                                    :key="step"
                                ></v-question>
                            </transition>
                        </div>
                        <v-result v-else
                                  :id="id"
                                  :questions="questions"
                                  :answers="answers"
                                  :text="resultText"
                                  @onResult="resultHandler"
                        ></v-result>
                    </transition>
                </div>

                <div v-if="!result" class="quiz__tool">
                    <!--                    <v-progress></v-progress>-->
                    <div class="quiz__nav" v-if="questions?.length > 2">
                        <v-button cls="bt_prev" @onClick="prevHandler">Назад</v-button>
                        <transition name="opacity">
                            <v-button cls="bt_prev" @onClick="nextHandler">Вперед</v-button>
                        </transition>
                    </div>
                    <template v-else>
                        <v-button class="btn_primary" @onClick="resultViewHandler">
                            Посмотреть результат
                        </v-button>
                    </template>
                </div>
            </div>
            <div v-else class="quiz-splash quiz__splash" @click="activateHandler">
                <v-preloader v-if="progress"></v-preloader>
                <template v-else>
                    <!--                    <div class="quiz-splash__image"></div>-->
                    <div class="quiz-splash__sub-title">Ответьте на несколько вопросов, это быстро!</div>
                    <div class="quiz-splash__title">{{name}}</div>
                    <div class="quiz-splash__description">{{description}}</div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
    import {computed, ref} from "vue";

    import Close from "@components/ui/Close";
    import Button from "@components/ui/forms/Button";
    import Result from "@components/quiz/Result";
    import Question from "@components/quiz/Question";
    import Preloader from "@components/ui/Preloader";

    import useQuiz from "@use/data/quiz/quiz";

    export default {
        name: "Quiz",
        components: {
            'v-close': Close,
            'v-button': Button,
            'v-result': Result,
            'v-question': Question,
            'v-preloader': Preloader,
        },
        props: {
            mini: {
                type: Boolean,
                default: true
            },
            id: {
                type: Number,
                default: null
            }
        },
        setup(props) {
            let progress = ref(true);
            let activate = ref(false);
            let step = ref(0);
            let result = ref(false);
            let name = ref('');

            let minimal = ref(null);
            let resultDefault = ref(null);
            let resultDone = ref(null);
            let resultText = ref(null);

            let answers = ref(null);

            getData();

            let questions = ref(null);
            let question = computed(() => {
                if (questions.value) {
                    if (step.value < questions.value?.length ?? 0) {
                        return questions.value[step.value];
                    }
                    return questions[step];
                }

                return null
            })

            async function getData() {
                if (!questions?.value) {
                    const {fetchData} = useQuiz(props.id);
                    let data = await fetchData();

                    if (data) {
                        questions.value = data.question;
                        name.value = data.name;
                        minimal.value = data.minimal;
                        resultDefault.value = data.result_default;
                        resultDone.value = data.result_done;
                        progress.value = false;
                    }
                }
            }

            function activateHandler() {
                getData();
                activate.value = true;
            }

            function closeHandler() {
                activate.value = false;
            }

            function getResult(questions) {
                let items = [];

                questions.map((question) => {
                    question.answer.map((answer) => {
                        if (answer.check) {
                            items.push(answer.answer);
                        }
                    })
                })

                return items;
            }

            function resultViewHandler() {
                answers.value = getResult(questions.value);
                resultText.value = answers.value.length >= minimal.value ? resultDefault.value : resultDone.value;
                result.value = true;
            }

            function resultHandler() {
                result.value = false;
                activate.value = false;
            }

            function nextHandler() {

            }

            function prevHandler() {

            }

            return {
                name,
                progress,
                activate,
                step,
                question,
                questions,
                result,
                answers,
                resultText,

                activateHandler,
                closeHandler,
                prevHandler,
                nextHandler,
                resultViewHandler,
                resultHandler
            }
        }
    }

    // function getQuestion() {
    //     return [
    //         {
    //             title: 'Вопрос 1',
    //             id: 1,
    //             type: 'once',
    //             required: false,
    //             answers: [
    //                 {
    //                     title: 'Ответ 1',
    //                     id: 1,
    //                     value: 100,
    //                     date: 12,
    //                     check: false,
    //                     type: 'checkbox'
    //                 }, {
    //                     title: 'Ответ 2',
    //                     id: 2,
    //                     value: 100,
    //                     date: 12,
    //                     check: false,
    //                     type: 'checkbox'
    //                 }, {
    //                     title: 'Ответ 3',
    //                     id: 3,
    //                     value: 100,
    //                     date: 12,
    //                     check: false,
    //                     type: 'checkbox'
    //                 }, {
    //                     title: 'Ответ 4',
    //                     id: 4,
    //                     value: 100,
    //                     date: 12,
    //                     check: false,
    //                     type: 'checkbox'
    //                 }, {
    //                     title: 'Ответ 5',
    //                     id: 5,
    //                     value: 100,
    //                     date: 12,
    //                     check: false,
    //                     type: 'checkbox'
    //                 }
    //             ]
    //         }, {
    //             title: 'Вопрос 2',
    //             id: 2,
    //             type: 'multi',
    //             required: true,
    //             answers: [
    //                 {
    //                     title: 'Ответ 1',
    //                     id: 1,
    //                     value: 100,
    //                     date: 12,
    //                     check: false
    //                 }, {
    //                     title: 'Ответ 2',
    //                     id: 2,
    //                     value: 100,
    //                     date: 12,
    //                     check: false
    //                 }, {
    //                     title: 'Ответ 3',
    //                     id: 3,
    //                     value: 100,
    //                     date: 12,
    //                     check: false
    //                 }, {
    //                     title: 'Ответ 4',
    //                     id: 4,
    //                     value: 100,
    //                     date: 12,
    //                     check: false
    //                 }, {
    //                     title: 'Ответ 5',
    //                     id: 5,
    //                     value: 100,
    //                     date: 12,
    //                     check: false
    //                 }
    //             ]
    //         }
    //     ]
    // }
</script>

<style lang="less">
    .quiz-splash {
        cursor: pointer;
        user-select: none;
        height: 100%;
        padding: 20px 30px;
        box-sizing: border-box;

        &__title {
            font-size: var(--h5);
            font-weight: bold;
        }

        &__sub-title {
            color: var(--gray);
            font-size: var(--p);
            margin-bottom: .5rem;
        }

        &__description {
            font-size: 15px;
            color: white;
        }
    }

    .quiz {
        user-select: none;
        border-radius: var(--border-radius);
        margin: 1rem auto;
        max-width: 640px;
        height: 110px;
        box-shadow: var(--shadow-base);
        overflow: hidden;

        position: relative;
        transition: all .3s ease;
        /*background: var(--lt-gray);*/
        background: var(--secondary-lighter);

        &_activate {
            background: var(--white);
            max-width: 100%;
            min-height: 420px;
            height: 100%;
        }

        &__question {
            display: flex;
            padding: 30px;
            box-sizing: border-box;
            flex-direction: column;
            height: 100%;
        }

        &__close {
        }

        &__title {
            font-size: 16px;
            margin-bottom: 20px;
        }

        &__header {
            /*height: 50px;*/
        }

        &__steps {
            display: inline-block;
            padding: 8px 13px;
            border-radius: 16px;
            background: #F2F2F2;
            color: #666;
            font-size: 13px;

            span {
                color: #000;
                font-weight: bold;
            }
        }

        &__content {
            flex-grow: 1;
        }

        &__nav {
            /*height: 80px;*/
        }
    }
</style>
