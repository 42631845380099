import {computed, reactive} from 'vue'
import useField from "@use/field";

/**
 *
 * @param init: Object
 * @returns {UnwrapNestedRefs<{}>}
 *
 Пример объекта:
 для создание и валидации полей формы

let form = {
    captcha: true,
    title: null,
    description: null,
    field: {
        name: {
            name: 'name',
            label: 'Email',
            value: '',
            type: 'text',
            clear: true,
            help: 'контактная электронная почта',
            validators: {
                required: {
                    validator: required,
                    message: useTemplate`${'name'} Обязательное поле для заполнения `,
                },
                minLength: {
                    validator: minLength(5),
                    pattern: '',
                    value: 5,
                    message: useTemplate`${'name'} Слишком короткое поле ${'length'} ${'value'}`,
                },
            },
        },
    }
}
 */

export default function useForm(init = {}) {
    const form = reactive({})
    form.fields = reactive({})
    form.errors = reactive({})

    form.title = init.title
    form.description = init.description

    for (const [key, value] of Object.entries(init.field)) {
        form.fields[key] = useField(value)
    }

    form.valid = computed(() => {
        return Object.keys(form.fields)
            .filter((key) => key !== 'valid')
            .reduce((acc, key) => {
                return acc = acc && form.fields[key].valid
            }, true)
    })

    return form
}
