<template>
    <div class="feature">
        <div class="feature__label">Специализация</div>
        <ul class="feature__value specialty">
            <li v-for="item in items"
                :key="item"
                class="specialty__item nav__item">
                <a class="nav__link" :href="item.link">{{ item.name }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Specialty",
        props: {
            items: {
                type: Object
            }
        }
    }
</script>

<style></style>
