import sliderSimple from "@modules/ui/sliders/sliderSimple";
import useSwitch from "@use/native/switch";

export default class Gallery {
    constructor(el, options) {
        this.tpl = {
            preloader: `<div class="preloader"></div>`,
        }

        const _default = {
            className: 'gallery',
            delay: 4000,
            auto: false,
            onChange: () => {
            },
        }

        this.options = Object.assign({}, _default, options);

        this.$el = el;
        this.$gallery = this.$el.querySelector('.gallery__list');

        if (this.$gallery){
            this.slider = new sliderSimple(this.$gallery, {
                auto: false,
                points: false,
                arrows: false,
            });

            this.$thumbnails = this.$el.querySelectorAll('.gallery__thumbnail');
            if (this.$thumbnails.length){
                useSwitch(this.$thumbnails, (item, index)=>{
                    this.slider.change(index);
                    this.options.onChange(this);
                }, 'gallery__thumbnail_active');
            }
        }
    }
}
