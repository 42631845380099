import {createStore} from 'vuex'

import shelf from '@store/modules/shelf'
import ordering from '@store/modules/ordering'
import search from '@store/modules/search'
import navigation from '@store/modules/navigation'
import information from '@store/modules/information'

export const store = createStore({
    state: () => ({
        API_URL: '',
    }),
    modules: {
        shelf,
        ordering,
        search,
        navigation,
        information
    }
})
