import config from "@config/config";
import useScrollTo from "@use/native/scrollTo";

import {nav} from "@templates/nav"

export default function sidebarScrollNav($sidebar, $content) {
    $sidebar.insertAdjacentHTML('afterbegin', nav({cls: 'nav_sidebar'}))

    let $nav = $sidebar.querySelector('.nav');
    let $navItems = [];
    let $navList = $nav.querySelector('.nav__list');
    let $blocks = $content.querySelectorAll('.block');

    let $selector = document.createElement("div");
    $selector.classList.add('nav__selector')
    $nav.appendChild($selector);

    function scrollForBlockHandler() {
        let windowTop = window.scrollY;
        const distToSwitch = 150;
        let index = 0;

        for (const item of [...$blocks]) {
            let top = item.getBoundingClientRect().top + window.scrollY;
            if (top - windowTop < distToSwitch) {
                index = [...$blocks].indexOf(item)
            }
        }

        let target = $navItems[index];
        if (target) {
            $selector.style.top = `${target.offsetTop}px`;
            $selector.style.height = `${target.clientHeight}px`;
        }
    }

    for (const item of [...$blocks]) {
        let $item = document.createElement("li");
        let title = item.querySelector('.block__title');
        $item.classList.add('nav__item')
        $item.innerHTML = title.innerHTML;
        $navList.appendChild($item);
        $navItems.push($item);

        $item.addEventListener('click', () => {
            useScrollTo(item, config.size.el.headerFixed);
        })
    }

    document.addEventListener('scroll', scrollForBlockHandler);
    scrollForBlockHandler();
    return {scrollForBlockHandler}
}
