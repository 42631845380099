<template>
    <transition name="opacity" mode="out-in">
        <div v-if="!send" class="feedback"
             :class="{'feedback_progress': progress}">

            <div v-if="progress" class="preloader"></div>

            <div class="feedback__header">
                <div v-if="title" class="feedback__title">{{title}}</div>

                <transition name="opacity" mode="out-in">
                    <suspense>
                        <template #default>
                            <v-specialist v-if="specialist"
                                          :item="specialist"
                                          class="feedback__specialist">
                            </v-specialist>
                        </template>
                        <template #fallback>
                            <div class="preloader"></div>
                        </template>
                    </suspense>
                </transition>

                <transition name="opacity" mode="out-in">
                    <suspense>
                        <template #default>
                            <div v-if="service"
                                 class="feedback__service">{{service.name}}</div>
                        </template>
                        <template #fallback>
                            <div class="preloader"></div>
                        </template>
                    </suspense>
                </transition>
            </div>

            <div class="feedback__body feedback__body_gray" :style="{'opacity': progress}">
                <div class="feedback__information">
                    <v-form
                        class="feedback__form"
                        :form="form"
                        :submitBtn="false"
                        @onSubmit="onSubmit"
                        url=""></v-form>
                </div>
            </div>

            <div class="feedback__footer">
                <v-button class="btn_primary btn_lg"
                          @onClick="onSubmit"
                          :disabled="!form.valid">Задать вопрос
                </v-button>
            </div>
        </div>
        <v-thanks v-else class="thanks thanks_ticket"
                  title="Спасибо что обратились к нам!"
                  description="Как только ответ будет опубликован вы получите оповещение на указанный Email"
        ></v-thanks>
    </transition>
</template>

<script>
    import {onMounted, ref} from "vue";
    import {reactive} from "@vue/reactivity";

    import Form from "@components/ui/forms/Form";
    import Button from "@components/ui/forms/Button";
    import Thanks from "@components/feedback/Thanks";
    import Specialist from "@components/specialist/Specialist";

    import {email, required} from "@utils/validators";

    import useForm from "@use/form";
    import useTemplate from "@use/native/template";
    import useTicket from "@use/data/relations/ticket";
    import useSpecialist from "@use/data/specialists/specialist";
    import useService from "@use/data/services/service";

    export default {
        name: "Ticket",
        components: {
            "v-form": Form,
            "v-button": Button,
            "v-thanks": Thanks,
            "v-specialist": Specialist,
        },
        props: {
            title: {
                type: String
            },
            dataset: {
                type: Object
            },
            resetTimeOut: {
                type: Number,
                default: 6000
            }
        },
        setup(props) {
            // const store = useStore();
            let date = ref(null);

            let data = reactive(props.dataset);
            let send = ref(false);

            let specialistID = null,
                serviceID = null,
                specialist = ref(null),
                service = ref(null);

            specialistID = ref(null);
            serviceID = ref(null);

            let progress = ref(false);

            async function getSpecialist(id) {
                const {fetchData} = useSpecialist(id);
                specialist.value = await fetchData();
                console.log(specialist.value);
            }

            async function getService(id) {
                const {fetchData} = useService(id);
                service.value = await fetchData();
                console.log(service.value);
            }

            onMounted(() => {
                data = props.dataset;
                if('dataset' in props){
                    if ('specialist' in data) {
                        specialistID.value = data.specialist;
                        getSpecialist(parseInt(data.specialist));
                    }

                    if ('service' in data) {
                        serviceID.value = data.service;
                        getService(parseInt(data.service));
                    }
                }
            });

            function onSubmit() {
                // console.log('onSubmit');
                // console.log(data, props.dataset);
                // console.log(specialist.value);
                // console.log(service.value);
                // console.log(specialistID.value);
                // console.log(serviceID.value);

                submit({
                    name: form.fields['name'].value,
                    email: form.fields['email'].value,
                    question: form.fields['question'].value,
                    author: form.fields['author'].value,
                    specialist_id: specialistID.value,
                    service_id: serviceID.value,
                });
            }

            async function submit(data) {
                progress.value = true;
                const {fetchData} = useTicket(data);
                let result = await fetchData();
                progress.value = false;

                setTimeout(() => {
                    if(send.value === true){
                        send.value = false;
                        reset();
                    }
                }, props.resetTimeOut);

                send.value = true;
                console.log(result);
            }

            const form = useForm({
                captcha: true,
                title: null,
                description: null,
                field: {
                    name: {
                        name: 'name',
                        label: 'Вопрос',
                        value: '',
                        required: true,
                        type: 'text',
                        clear: true,
                        validators: {
                            required: {
                                validator: required,
                                message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                            },
                        },
                    },
                    question: {
                        name: 'question',
                        label: 'Вопрос - развёрнутое пояснение',
                        value: '',
                        type: 'textarea',
                        // clear: true,
                        // validators: {
                        //     required: {
                        //         validator: email,
                        //         message: useTemplate`Не верный формат ${'label'}`,
                        //     },
                        // },
                    },
                    author: {
                        name: 'author',
                        label: 'Ваше имя',
                        value: '',
                        type: 'text',
                        clear: true,
                        // validators: {
                        //     required: {
                        //         validator: required,
                        //         message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                        //     },
                        // },
                    },
                    email: {
                        name: 'email',
                        label: 'Email',
                        value: '',
                        type: 'email',
                        clear: true,
                        help: 'Указав Email вы получите уведомление об ответе на вопрос',
                        validators: {
                            required: {
                                validator: email,
                                message: useTemplate`Не верный формат ${'label'}`,
                            },
                        },
                    },
                }
            })

            function reset() {
                Object.keys(form.fields).map((key)=>{
                    form.fields[key].value = ''
                    form.fields[key].status = false;
                })
            }

            return {
                service,
                specialist,
                progress,
                date,
                data,
                status,
                send,
                form,
                onSubmit,
            }
        }
    }
</script>

<style></style>
