import useResponse from "@use/response";
import useDrag from "@use/native/drag";
import useDebug from "@use/native/debug";
import usePoints from "@modules/ui/points";
import Arrows from "@modules/ui/arrows";

export default class SliderLeap {
    constructor(el, options) {
        this.tpl = {
            preloader: `<div class="preloader"></div>`,
            arrows: `<div class="slider__arrows">
                        <div class="slider__arrow slider__arrow_prev"></div>
                        <div class="slider__arrow slider__arrow_next"></div>
                    </div>`,
            viewport: `<div class="slider__viewport"></div>`,
            container: `<div class="slider__container"></div>`
        }

        const _default = {
            className: 'slide',
            direction: 'horizontal', // horizontal, vertical
            overflow: 'hidden',
            items: null,
            delay: 4000,
            time: .3,

            view: 1,
            margin: 0,
            padding: 0,

            auto: false,
            loop: false,
            touch: true,
            activate: true,

            points: true,
            arrows: true,
            color: null, /** #FFFFFF **/
            response: null, /** response: {0:{items: 12,}} **/
            onChange: () => {},
            onBuild: () => {},
        }

        this.options = Object.assign({}, _default, options);

        this.step = 0;
        this.quantity = 0;
        this.one = false;

        this.offset = 0;
        this.time = 0;
        this.drag = false;

        this.resizeTimeOutId = null;
        this.changeTimeOutId = null;

        this.$el = el;
        this.$el.classList.add('slider');

        this.$arrows = null;
        this.$points = null;
        this.$clone = null;
        this.$items = null;

        this.$prevClone = null;
        this.$nextClone = null;

        if (this.options.items) {
            this.$slides = [...this.$el.querySelector(this.options.className)];
        } else {
            this.$slides = [...this.$el.children];
        }

        this.total = this.$slides.length;

        this.$el.insertAdjacentHTML('afterbegin', this.tpl.viewport);
        this.$viewport = this.$el.querySelector('.slider__viewport');

        this.$viewport.insertAdjacentHTML('afterbegin', this.tpl.container);
        this.$container = this.$viewport.querySelector('.slider__container');

        for (const item of this.$slides) {
            this.$container.appendChild(item);
            if (!item.classList.contains('slide')) {
                item.classList.add('slide');
            }
        }

        this.settings = {
            view: this.options.view,
            margin: this.options.margin,
            padding: this.options.padding,
            touch: this.options.touch,
            arrows: this.options.arrows,
            points: this.options.points,
            loop: this.options.loop,
            auto: this.options.auto,
            activate: this.options.activate,
            direction: this.options.direction,
        }

        this.sizes = {
            window: 0,
            viewport: 0,
            container: 0,
            slide: 0,
            step: 0,
        }

        this.build = false;

        let {debug} = useDebug();
        this.debug = debug;

        this.render();
    }

    render() {
        let {callbacks} = useDrag(this.$viewport);

        callbacks.onMouseMove = (x, y) => this.onMouseMove(x, y);
        callbacks.onMouseDown = (drag) => this.drag = drag;
        callbacks.onMouseUp = (drag) => {
            this.drag = drag;
            this.onMouseMove(0);
        }

        window.addEventListener('resize', () => {
            if (this.resizeTimeOutId) {clearTimeout(this.resizeTimeOutId);}
            this.resizeTimeOutId = setTimeout(() => this.Resize(), 150);
        });

        this.calculation();
        // this.show();

        if (this.settings.direction === 'horizontal') {
            callbacks.onSwipeRight = () => this.onPrev(false);
            callbacks.onSwipeLeft = () => this.onNext(false);
        } else {
            callbacks.onSwipeUp = () => this.onNext(false);
            callbacks.onSwipeDown = () => this.onPrev(false);
        }
    }

    getPosition(mouseOffset = 0) {
        return Math.round(-((this.step * this.sizes.step) + this.offset + (this.step * this.settings.margin))) + mouseOffset;
    }

    getWindowSize(size = `Width`) {
        return window[`inner${size}`]
            || document.documentElement[`client${size}`]
            || document.body[`client${size}`]
    }

    getTargetSize(target) {
        return target[`client${this.settings.direction === 'horizontal' ? `Width` : `Height`}`]
    }

    getTranslate() {
        return this.settings.direction === 'horizontal' ? `translateX` : `translateY`
    }

    getSizeAttr() {
        return this.settings.direction === 'horizontal' ? `width` : `height`
    }

    getMarginAttr() {
        return this.settings.direction === 'horizontal' ? `margin-right` : `margin-bottom`
    }

    getSlideStyle() {
        return `${this.getSizeAttr()}: ${this.sizes.slide}px;
                ${this.getMarginAttr()}: ${this.settings.margin}px;
                opacity: 1;`
    }

    getClass(value) {
        return `slider_${value}`
    }

    getStyle(position) {
        return `transition-duration: ${this.drag ? 0 : this.time}s;
                ${this.getSizeAttr()}: ${this.sizes.container}px;
                transform: ${this.getTranslate()}(${this.getPosition(position)}px)`
    }

    cloning(view) {
        if (this.total >= view) {
            const start = this.total - view;

            if (this.$clone) {
                this.$clone.forEach((el) => {
                    el.parentElement.removeChild(el)
                })
                this.$clone = null;
            }

            const prevClone = this.$slides.slice(start, start + view);
            const nextClone = this.$slides.slice(0, view);

            this.prevCloning(prevClone, this.$slides[0]);
            this.prevCloning(nextClone, this.$slides[this.$slides.length]);

            this.$clone = [...this.$container.querySelectorAll('.clone')];
        } else {
            this.prev_clone = this.next_clone = []
        }
    }

    prevCloning(nodes, target) {
        [...nodes].forEach((node) => {
            let item = document.createElement("div");
            item.insertAdjacentHTML('afterbegin', node.innerHTML);
            item.setAttribute('class', node.getAttribute('class'));
            if (node.getAttribute('style')) item.setAttribute('style', node.getAttribute('style'));
            item.classList.add('clone');
            this.$container.insertBefore(item, target);
        });
    }

    setResponsive() {
        let response = useResponse(this.options.response, this.sizes.window);
        this.settings.view = response.items ? response.items : this.options.view;

        this.settings.margin = this.settings.view === 1 ? 0 : response.margin ? response.margin : this.options.margin;
        this.settings.padding = response.padding ? response.padding : this.options.padding;
        this.settings.auto = response.margin ? response.auto : this.options.auto;
        this.settings.direction = 'direction' in response ? response.direction : this.options.direction;

        this.settings.points = response.points !== undefined ? response.points : this.options.points;
        this.settings.arrows = response.arrows !== undefined ? response.arrows : this.options.arrows;
    }

    getRemainder(total, view) {
        if (total % view !== 0) {
            return true;
        } else return view === 1;
    }

    getQuantity() {
        /*Если кол-ва слайдов меньше кол-ва показываемых слайдов*/
        if (this.total < this.settings.view) return 0;

        if (this.options.loop) {
            if (this.one) return this.total;

            return (this.total / this.settings.view);
        } else {
            if (this.one) return (this.total - this.settings.view) + 1;

            return (this.total / this.settings.view);
        }
    }

    calculation() {
        this.sizes.window = this.getWindowSize();
        if (this.options.response) this.setResponsive();

        this.one = this.getRemainder(this.total, this.settings);
        this.quantity = this.getQuantity();

        this.$el.classList.remove(this.getClass('vertical'), this.getClass('horizontal'));
        this.$el.classList.add(this.getClass(this.settings.direction));

        this.sizes.viewport = this.getTargetSize(this.$viewport);

        this.sizes.container = this.options.loop
            ? (this.sizes.viewport + this.settings.margin) * (this.total + this.settings.view * 2)
            : (this.sizes.viewport + this.settings.margin) * this.total;

        this.sizes.slide = Math.round((this.sizes.viewport - ((this.settings.view - 1) * this.settings.margin)) / this.settings.view)
        // this.sizes.step = Math.round(this.one ? this.sizes.slide + this.options.margin : this.sizes.viewport + this.settings.margin);
        this.sizes.step = Math.round(this.one ? this.sizes.slide + this.options.margin / 2 : this.sizes.viewport + this.settings.margin);

        if (this.options.loop) {
            if (this.quantity) {
                this.offset = (this.sizes.slide + this.settings.margin) * this.settings.view;
                this.cloning(this.settings.view);
            } else {
                this.offset = 0;
            }
        }

        if (this.step >= this.quantity) {
            this.step = 0
        }

        this.addArrow();
        this.addPoint();

        // this.debug(this.settings);

        this.$items = this.$container.children;
        this.$container.style = this.getStyle();
        for (const item of this.$items) {
            item.style = this.getSlideStyle();
        }

        if(!this.build){
            this.build = true;
            this.options.onBuild(this);
        }
    }

    onChange(value) {
        if (this.step !== value) this.change(value)
    }

    change(value, transition = true, direct = true) {
        if (this.quantity) {
            if (!this.options.loop) {
                if (value >= this.quantity) {
                    value = 0;
                } else if (value < 0) {
                    value = this.quantity - 1;
                }
            } else {
                if (direct) {
                    let start = null;
                    if (transition) {
                        if (value >= this.quantity) {
                            value = 0;
                            start = -1;
                        } else if (value < 0) {
                            value = this.quantity - 1;
                            start = this.quantity;
                        }
                    }

                    if (start !== null) {
                        this.time = 0;
                        this.step = start;
                        this.$container.style = this.getStyle();
                        this.changeTimeOutId = setTimeout(() => {
                            this.change(value);
                            return false
                        }, 1);
                        return false;
                    }
                } else {
                    let end = null;
                    if (transition) {
                        if (value >= this.quantity) {
                            value = this.quantity;
                            end = 0;
                        } else if (value < 0) {
                            value = -1;
                            end = this.quantity - 1;
                        }
                    }

                    if (end !== null) {
                        this.changeTimeOutId = setTimeout(() => {
                            this.change(end, false);
                        }, 300);

                        if (this.settings.points) {
                            if (this.$points) {
                                this.$points.activation(end);
                            }
                        }
                    }
                }
            }
        } else {
            value = 0
        }

        if (transition) {
            this.time = this.options.time;
        } else {
            this.time = 0;
        }

        // this.activate();
        this.step = value;
        if (this.settings.points) {
            if (this.$points) {
                this.$points.activation(this.step);
            }
        }

        this.$container.style = this.getStyle();
        for (const slide of this.$slides) {
            slide.style = this.getSlideStyle();
        }

        this.options.onChange(this);
    }

    activate() {
        let min, max = 0;
        max = min + this.settings.view;

        if (this.one) {
            if (this.options.loop) {
                min = this.settings.view + this.step;
            } else {
                min = this.step;
            }
            max = min + this.settings.view;
        } else {
            if (this.options.loop) {
                min = this.settings.view * (this.step + 1);
                max = min + this.settings.view;
            } else {
                min = this.step;
            }
        }

        // console.log(min, max, 'min - max', one.value, step.value, props.loop);
        for (let i = 0; i < this.total; ++i) {
            if (i >= min && i < max) {
                this.$slides[i].classList.add('slide_active');
            } else {
                this.$slides[i].classList.remove('slide_active');
            }
        }
    }

    addPoint() {
        if (this.settings.points) {
            if (!this.$points) {
                this.$points = new usePoints(this.$viewport);

                this.$points.render(this.quantity, this.step);
                this.$points.activation(this.step);

                this.$points.callback.onChange = (index) => {
                    this.change(index);
                };
            } else {
                this.$points.render(this.quantity, this.step);
                this.$points.show();
            }
        } else {
            if (this.$points) {
                this.$points.hide();
            }
        }
    }

    addArrow() {
        if (this.settings.arrows) {
            if (!this.$arrows) {
                const {$arrows, callbacks} = Arrows(this.$viewport);
                this.$arrows = $arrows;

                callbacks.onPrev = () => this.onPrev();
                callbacks.onNext = () => this.onNext();
            } else {
                this.$arrows.style.display = 'flex';
            }
        } else {
            if (this.$arrows) {
                this.$arrows.style.display = 'none';
            }
        }
    }

    onMouseMove(x, y) {
        this.$container.style = this.settings.direction === 'horizontal' ? this.getStyle(x) : this.getStyle(y);
    }

    onPrev(start = true) {
        this.change(this.step - 1, true, start)
    }

    onNext(start = true) {
        this.change(this.step + 1, true, start)
    }

    getChild() {
        return this.$items;
    }

    getView() {
        return this.settings.view;
    }

    Resize() {
        this.calculation();
    }
}
