<template>
    <div class="field"
         :class="{field_invalid: !field.valid && field.status}">

        <div class="field__wrapper">
            <transition name="opacity">
                <div v-if="!field.empty"
                     class="field__clear close"
                     @click="onClear"
                     title="Очистить поле"></div>
            </transition>

            <label v-if="field.label"
                   class="field__label"
                   :for="field.name">{{field.label}} <span v-if="field.required">*</span></label>

            <textarea v-if="field.type==='textarea'" class="field__textarea"
                   :id="field.name"
                   autocomplete="off"
                   @blur="field.blur"
                   @input="onInput"
                   v-model="value"></textarea>
            <input v-else class="field__input"
                   :id="field.name"
                   :type="field.type"
                   autocomplete="off"
                   @blur="field.blur"
                   @input="onInput"
                   v-model="value">
        </div>

        <small v-if="field.help" class="field__help">{{field.help}}</small>
        <transition name="fade">
            <v-errors v-if="field.status && !field.valid"
                      :errors="field.errors"
                      class="field__error"
            ></v-errors>
        </transition>
    </div>
</template>

<script>
    import {ref} from 'vue'
    import Errors from "@components/ui/forms/Errors";

    export default {
        name: "Field",
        components: {
            "v-errors": Errors
        },
        props: {
            field: Object,
        },
        setup(props, {emit}) {
            const value = ref(props.field.value)

            function onClear() {
                value.value = ''
                emit('update:value', '')
            }

            function onInput(e) {
                return emit('update:value', e.target.value.trim())
            }

            return {
                value,
                onInput,
                onClear,
            }
        },
    }
</script>

<style lang="less">
    .field {
        margin-bottom: 1rem;

        &__label {
            display: flex;
            margin-bottom: 10px;
            font-size: var(--label-size);
            color: var(--gray);

            span {
                font-size: var(--p);
                font-weight: bold;
                color: var(--primary);
            }
        }

        &__textarea,
        &__input {
            display: flex;
            height: 40px;
            width: 100%;

            font-size: 16px;
            padding: 5px 10px;
            box-sizing: border-box;

            border: var(--border-size) solid var(--border-color);
            outline: 0;

            &:focus {
                border: var(--border-size) solid var(--success);
            }
        }

        &__textarea{
            resize: none;
            min-height: 120px;
            width: 100%;
        }

        &__help {
            font-size: 11px;
            color: gray;
        }

        &__wrapper {
            position: relative;
        }

        &__clear {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 30px;
            height: 30px;

            position: absolute;
            top: auto;
            bottom: 5px;
            right: 5px;

            background: var(--secondary-dark);

            border-color: var(--secondary-dark);
            border-radius: var(--border-radius-sm);

            transition: background-color .3s ease-in-out;

            &:hover{
                background: var(--secondary-darker);
                border-color: var(--secondary-darker);
            }
        }

        &_invalid {
            .field {
                &__input {
                    border: var(--border-size) solid var(--danger) !important;
                }
            }
        }

        &__errors {

        }
    }
</style>
