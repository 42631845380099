/**
 *
 * @param value Число для которого нужно вернуть сроку
 * @param arg  Массив "аргумент, аргумента, аргументов"
 * @returns {*|string}
 */
export function pluralize(value, ...arg) {
    let args = [...arg];
    if (args.length === 3) {
        let number = Math.abs(parseInt(value)),
            a = number % 10,
            b = number % 100;

        if ((a === 1) && (b !== 11)) {
            return args[0]
        } else if ((a >= 2) && (a <= 4) && ((b < 10) || (b >= 20))) {
            return args[1]
        } else {
            return args[2]
        }
    } else {
        throw new Error(`This arg ${args} not 3`)
    }
}

/**
 *
 * @param chars
 * @param size
 * @returns {string}
 */
export function generatePassword(chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@/./+/-/_.', size) {
    let password = '';
    const length = chars.length;

    for (let i = 0; i < size; i++) {
        let num = Math.floor(Math.random(length - 1));
        password += chars.substr(num, 1)
    }
    return password
}

/**
 *
 * @param chars
 * @param id
 * @returns {string}
 */
export function letter(chars = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ', id) {
    return id ? chars.substr(id, 1) : '';
}

/**
 *
 * @param str
 * @returns {string}
 */
export function translate(str) {
    /**
     * Преобразует спроку в транстил
     * @type {Map<string, string>}
     */
    const ru = new Map([
        ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'],
        ['є', 'e'], ['ё', 'e'], ['ж', 'j'], ['з', 'z'], ['и', 'i'], ['ї', 'yi'], ['й', 'i'],
        ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
        ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'],
        ['ш', 'sh'], ['щ', 'shch'], ['ы', 'y'], ['э', 'e'], ['ю', 'u'], ['я', 'ya'],
    ]);

    let clear = str.replace(/[ъь]+/g, '');
    return Array.from(clear)
        .reduce((s, l) =>
            s + (
                ru.get(l)
                || ru.get(l.toLowerCase()) === undefined && l
                || ru.get(l.toLowerCase()).toUpperCase()
            ), '');
}

/**
 *
 * @param value
 * @returns {*}
 */
export function getNumber(value) {
    /**Возвращает стороку содержащую цифры*/
    return value.replace(/\D/g, "");
}

/**
 *
 * @param value
 * @returns {string}
 */
export function getPhoneLink(value) {
    /**Возвращает телефон без тире и скобок*/
    let reg = "()/- "
    for(const char of reg){
        value = value.replace(new RegExp(`\\${char}`,'g'), "")
    }
    return value
}
