<template>
    <transition name="opacity" mode="out-in">
        <div v-if="!send" class="feedback"
             :class="{'feedback_progress': progress}">

            <div v-if="progress" class="preloader"></div>

            <div class="feedback__header">
                <div v-if="title" class="feedback__title">{{title}}</div>

                <transition name="opacity" mode="out-in">
                    <suspense>
                        <template #default>
                            <v-specialist v-if="specialist"
                                          :item="specialist">
                            </v-specialist>
                        </template>
                        <template #fallback>
                            <div class="preloader"></div>
                        </template>
                    </suspense>
                </transition>

                <transition name="opacity" mode="out-in">
                    <suspense>
                        <template #default>
                            <div v-if="service" class="feedback__service">{{service.name}}</div>
                        </template>
                        <template #fallback>
                            <div class="preloader"></div>
                        </template>
                    </suspense>
                </transition>


<!--                <transition name="opacity" mode="out-in">-->
<!--                    <suspense>-->
<!--                        <template #default>-->
<!--                            <div v-if="offer" class="feedback__offer">{{offer.name}}</div>-->
<!--                        </template>-->
<!--                        <template #fallback>-->
<!--                            <div class="preloader"></div>-->
<!--                        </template>-->
<!--                    </suspense>-->
<!--                </transition>-->
            </div>


            <div class="feedback__body feedback__body_gray" :style="{'opacity': progress}">
                <div class="feedback__information">
                    <v-form
                        class="feedback__form"
                        :form="form"
                        :submitBtn="false"
                        @onSubmit="onSubmit"
                        url=""></v-form>
                </div>
            </div>

            <div class="feedback__footer">
                <v-button class="btn_primary btn_lg"
                          @onClick="onSubmit"
                          :disabled="!form.valid">{{submitLabel}}
                </v-button>
            </div>
        </div>
        <v-thanks v-else class="thanks thanks_ticket"
                  :title="thanks.title"
                  :description="thanks.title"
        ></v-thanks>
    </transition>
</template>

<script>
    import {onMounted, ref} from "vue";
    import {reactive} from "@vue/reactivity";

    import Form from "@components/ui/forms/Form";
    import Button from "@components/ui/forms/Button";
    import Thanks from "@components/feedback/Thanks";
    import Specialist from "@components/specialist/Specialist";

    import useForm from "@use/form";
    import useSpecialist from "@use/data/specialists/specialist";
    import useService from "@use/data/services/service";

    export default {
        name: "Ticket",
        components: {
            "v-form": Form,
            "v-button": Button,
            "v-thanks": Thanks,
            "v-specialist": Specialist,
        },
        props: {
            title: {
                type: String
            },
            fields: {
                type: Object
            },
            dataset: {
                type: Object
            },
            fetch: {
                type: Object
            },
            thanks: {
                type: Object
            },
            resetTimeOut: {
                type: Number,
                default: 6000
            },
            submitLabel: {
                type: String,
                default: 'Отправить'
            },
        },
        setup(props) {
            let date = ref(null);

            let data = reactive(props.dataset);
            let send = ref(false);
            let keys = reactive([]);
            let progress = ref(false);

            let specialist = ref(null),
                service = ref(null),
                specialistID = ref(null),
                serviceID = ref(null);

            async function getSpecialist(id) {
                const {fetchData} = useSpecialist(id);
                specialist.value = await fetchData();
            }

            async function getService(id) {
                const {fetchData} = useService(id);
                service.value = await fetchData();
            }

            onMounted(() => {
                if ('dataset' in props) {
                    data = props.dataset;
                    if ('specialist' in data) {
                        specialistID.value = data.specialist;
                        getSpecialist(parseInt(data.specialist));
                    }

                    if ('service' in data) {
                        serviceID.value = data.service;
                        getService(parseInt(data.service));
                    }
                }
            });

            function onSubmit() {
                console.log('onSubmit');
                // console.log(data, props.dataset);
                // console.log(specialist.value);
                // console.log(service.value);
                // console.log(specialistID.value);
                // console.log(serviceID.value);

                let ctx = {};
                keys = Object.keys(form.fields);
                keys.map((key) => {
                    ctx[key] = form.fields[key].value
                })

                ctx['specialist_id'] = specialistID.value;
                ctx['service_id'] = serviceID.value;

                // console.log(ctx);
                submit(ctx);
            }

            async function submit(data) {
                progress.value = true;
                const {fetchData} = props.fetch(data);
                let result = await fetchData();
                progress.value = false;

                setTimeout(() => {
                    if (send.value === true) {
                        send.value = false;
                        reset();
                    }
                }, props.resetTimeOut);

                send.value = true;
                console.log(result);
            }

            const form = useForm({
                captcha: true,
                title: null,
                description: null,
                field: props.fields
            })

            function reset() {
                keys.map((key) => {
                    form.fields[key].value = ''
                    form.fields[key].status = false;
                })
            }

            return {
                service,
                specialist,
                progress,
                date,
                data,
                status,
                send,
                form,
                onSubmit,
            }
        }
    }
</script>

<style></style>
