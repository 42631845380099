<template>
    <form class="form" @submit="submitHandler">
        <div class="form__header">
            <div class="form__title" v-if="form.title">{{ form.title }}</div>
            <div class="form__description" v-if="form.description">{{ form.description }}</div>
        </div>

        <v-error v-for="error in form.errors" :key="error"></v-error>
        <v-field v-for="item in form.fields"
                 :key="item"
                 :field="item"
                 v-model:value="item.value"
        ></v-field>

        <v-button v-if="submitBtn" class="btn_primary"
                  @onClick="submitHandler"
                  :disabled="!form.valid">
            {{ submitLabel }}
        </v-button>

        <div v-if="terms" class="terms">
            Нажимая кнопку <strong>«{{ submitLabel }}»</strong>, вы подтверждаете, что ознакомились с
            <a href="/personal-data">Условиями обработки персональных данных</a> и принимаете их.
            Этот сайт защищен reCAPTCHA Enterprise и Google
            <a href="https://policies.google.com/privacy">Политика конфиденциальности</a> и
            Применяются <a href="https://policies.google.com/terms">Условия использования</a>.
        </div>
    </form>
</template>

<script>
import Button from "@components/ui/forms/Button";
import Errors from "@components/ui/forms/Errors";
import Field from "@components/ui/forms/Field";

export default {
    components: {
        "v-button": Button,
        "v-field": Field,
        "v-error": Errors,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
        name: {
            type: String
        },
        url: {
            type: String,
            required: true,
            default: ''
        },
        captcha: {
            type: Boolean,
            default: false
        },
        terms: {
            type: Boolean,
            default: true
        },
        submitBtn: {
            type: Boolean,
            default: false
        },
        submitLabel: {
            type: String,
            default: 'Отправить',
        }
    },
    name: "Form",
    setup(props, {emit}) {
        function submitHandler(e) {
            e.preventDefault();
            emit('onSubmit', props.form);
        }

        return {submitHandler}
    }
}
</script>

<style lang="less">
.form {
    &__title {
        font-size: var(--h2);
        margin-bottom: 20px;
    }

    &__description {
        font-size: var(--h5);
        margin-bottom: 15px;
    }

    &__errors {
        outline: none;
        list-style-type: none;
        margin: 0;
        padding: 5px 0;
    }
}
</style>
