export default class Video {
    constructor(trigger, target, options) {
        const _defaults = {
            width: '640', height: '480',
            class: null,
            play: false,
            onStop: function () {
            },
            onPause: function () {
            },
            onPlay: function () {
            },
        }

        this.options = Object.assign({}, _defaults, options);

        this.trigger = trigger;
        this.target = target;
        this.id = this.trigger.dataset.id;

        this.$youtubeApi = null;
        this.player = null;
        this.done = false;

        this.render();
    }

    render() {
        this.$youtubeApi = document.querySelector('#youtubeApi');

        if (!this.$youtubeApi) {
            this.$youtubeApi = document.createElement('script');
            this.$youtubeApi.setAttribute('id', 'youtubeApi');
            this.$youtubeApi.src = "https://www.youtube.com/iframe_api";
            let body = document.querySelector('body');

            body.insertBefore(this.$youtubeApi, body.firstChild);
        }
    }

    createVideo(target) {
        if (window.YT) {
            this.player = new window.YT.Player(target || this.target, {
                height: '100%',
                width: '100%',
                videoId: `${this.id}`,
                host: '//www.youtube.com',
                origin: window.location.href,
                // origin: 'http://127.0.0.1:9001/',
                events: {
                    // 'onReady': this.onPlayerReady,
                    'onStateChange': this.onPlayerStateChange
                }
            });
        }
    }

    onPlayerReady(e) {
        e.target.playVideo();
    }

    onPlayerStateChange(e) {
        console.log(e);

        // if (event.data == window.YT.PlayerState.PLAYING && !this.done) {
        //     setTimeout(this.stopVideo, 6000);
        //     this.done = true;
        // }
    }

    stopVideo() {
        this.player.stopVideo();
    }
}
