import useService from "@use/data/services/service";
import useSpecialist from "@use/data/specialists/specialist";
import useOffer from "@use/data/services/offer";

const ordering = {
    namespaced: true,
    state: () => ({
        specialist: null,
        service: null,
        offer: null,
        date: '',
        time: '',
        name: '',
        phone: '',
        email: '',
    }),
    getters: {
        isShow: (state) => {
            return state.show
        },
        specialistID: (state) => {
            if (state.specialist){
                return state.specialist.id
            }
            return null
        },
        serviceID: (state) => {
            if (state.service){
                return state.service.id
            }
            return null
        },
        offerID: (state) => {
            if(state.offer){
                return state.offer.id
            }
            return null
        },
        specialist: (state) => {
            return state.specialist
        },
        service: (state) => {
            return state.service
        },
        offer: (state) => {
            return state.offer
        }
    },
    mutations: {
        SHOW(state, payload) {
            state.show = payload;
        },
        ADD_SERVICE(state, payload) {
            state.service = payload;
            // console.log(state.service);
        },
        ADD_SPECIALIST(state, payload) {
            state.specialist = payload;
            // console.log(state.specialist);
        },
        ADD_OFFER(state, payload) {
            state.offer = payload
        },
        CLEAR(state) {
            // console.log('clear');
            state.specialist = null;
            state.service = null;
            state.offer = null;
        },
    },
    actions: {
        show(context, payload) {
            context.commit('SHOW', payload);
        },
        getData(context, payload){
            context.commit('CLEAR');
            if ('specialist' in payload) {
                if(payload.specialist){
                    this.dispatch('ordering/getSpecialist', payload.specialist);
                }
            }

            if ('service' in payload) {
                if(payload.service){
                    this.dispatch('ordering/getService', payload.service);
                }
            }

            if ('offer' in payload) {
                if(payload.offer){
                    this.dispatch('ordering/getOffer', payload.offer);
                }
            }
        },

        async getSpecialist(context, payload){
            const {fetchData} = useSpecialist(payload);
            let data = await fetchData();
            context.commit('ADD_SPECIALIST', data);
        },

        async getService(context, payload){
            const {fetchData} = useService(payload);
            let data = await fetchData();
            context.commit('ADD_SERVICE', data);
        },

        async getOffer(context, payload){
            const {fetchData} = useOffer(payload);
            let data = await fetchData();
            context.commit('ADD_OFFER', data);
        },
    },
    modules: {}
}


export default ordering
