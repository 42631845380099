<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: "CatalogNavigation",
    props: {
        id: {
            type: [Number, String],
            default: null
        },
        url: {
            type: String,
            default: null
        },
        activate: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore();
        let items = computed(() => store.getters['navigation/getItems'](props.id));
        let select = ref();

        if (props.activate) {
            store.dispatch('navigation/getItems', {url: props.url})
        }

        const selectHandler = (item) => {
            select.value = item
        }

        return {
            select,
            items,
            selectHandler
        }
    }
}
</script>

<template>
    <nav class="catalog-nav">
        <div v-if="!items" class="preloader"></div>
        <ul v-else class="catalog-nav__list">
            <li v-for="item in items"
                :key="item"
                class="catalog-nav__item"
                :class="[
                    {'catalog-nav__item_children': item?.children},
                    {'catalog-nav__item_active': item.active},
                    {'catalog-nav__item_select': item === select}
                ]"
            >
                <a :href="item.url"
                   class="catalog-nav__link catalog-nav__link_main"
                   @mouseenter="selectHandler(item)"
                >
                    <span v-if="item?.icon" class="catalog-nav__icon">
                       <img :src="item.icon">
                    </span>
                    <strong>{{ item.name }}</strong></a>
            </li>
        </ul>

        <ul v-if="select?.children" class="catalog-nav__sub-list">
            <li v-for="sub_item in select.children"
                :key="sub_item"
                class="catalog-nav__sub-item"
                :class="{'catalog-nav__sub-item_active': sub_item.active}">
                <a :href="sub_item.url" class="catalog-nav__link">{{ sub_item.name }}</a>
            </li>
        </ul>
    </nav>
</template>

<style lang="less">
@import "../../assets/css/extends/extends";
@import "../../assets/css/mixins/svg/ico";

.catalog-nav {
    --line-height: 1.5rem;
    background: var(--content-bg-color);
    display: flex;

    &__list,
    &__sub-list {
        box-sizing: border-box;
        overflow-y: scroll;
        &:extend(.scrollbar all);
    }

    &__list {
        padding: .5rem 0;
        background: var(--ex-lt-blue);
        display: flex;
        flex-direction: column;
        width: 32%;
        height: 80vh;
        overflow-x: hidden;
    }

    &__sub-list {
        padding: 2rem;
        flex: 1;
        width: 100%;
        column-count: 2;
        column-rule: solid 1px var(--ex-lt-blue);
        column-gap: 3rem;
        column-fill: balance;
        //break-inside: avoid;
        //break-before: column;
        //break-after: column;
    }

    &__icon {
        border: 1px solid var(--lt-gray);
        //background: var(--primary);
        margin-right: .75rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
    }

    &__item {
        position: relative;
        display: block;
        width: 100%;
        padding: .125rem 0 .125rem .5rem;
        margin-bottom: .75rem;
        box-sizing: border-box;
        line-height: var(--line-height);
        transition: background-color .3s ease;

        &_children {
            //background: red;
            &:after {
                content: '';
                position: absolute;
                right: -4px;
                top: calc(50% - 16px);
                .s-arrow-gray-right-sm();
            }
        }

        &_active {
            color: var(--primary);
            background: var(--lt-gray);
        }

        &_select {
            //color: var(--primary);
            background: var(--secondary-light);
        }
    }

    &__link {
        &_main {
            //font-weight: bold;
            margin-bottom: .25rem;
            display: flex;
            align-items: center;

            strong {
                flex: 1;
            }
        }
    }

    &__sub-item {
        margin-bottom: .5rem;
        line-height: var(--line-height);
        font-size: var(--label-size);

        &_active {
            color: var(--primary);
        }
    }

    &__item,
    &__sub-item {
        &_active {
            & > a {
                color: var(--primary);
            }
        }
    }
}

</style>
