<template>
    <div class="question">
        <transition name="opacity">
            <div class="question__content">
                <div class="question__title">{{title}}</div>
                <div class="question__list">
                    <v-answer v-for="(answer , index) in answers"
                              :item="answer"
                              :id="index"
                              :key="answer"
                              v-model:value="answer.check"
                    ></v-answer>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import Answer from "@components/quiz/Answer";

    export default {
        name: "Question",
        components: {
            'v-answer': Answer,
        },
        props: {
            title: String,
            id: Number,
            answers: Array,
            type: String,
        },
        setup() {
            return {}
        },
    }
</script>

<style scoped lang="less">
    .question {
        &__title {
            font-size: var(--h4);
            margin-bottom: 20px;
            height: 30px;
        }

        &__list {

        }
    }

    @keyframes slideIn {
        from {
            transform: translateX(-2000px)
        }
        to {
            transform: translateX(0px)
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0px)
        }
        to {
            transform: translateX(-2000px)
        }
    }
</style>
