<template>
    <div class="quiz-result">
        <template v-if="!send">
            <div class="quiz-result__info">
                <div class="quiz-result__text" v-html="text"></div>
            </div>

            <div class="quiz-result__form">
                <v-form
                    :form="form"
                    :submitBtn="true"
                    submitLabel="Записаться"
                    @onSubmit="submitHandler"
                    url=""></v-form>
            </div>
        </template>
        <v-thanks v-else class="thanks"
                  :title="thanks.title"
                  :description="thanks.title"
        ></v-thanks>
    </div>
</template>

<script>
    import {ref} from "vue";

    import Form from "@components/ui/forms/Form";
    import Thanks from "@components/feedback/Thanks";

    import useForm from "@use/form";
    import usePhoneMask from "@use/native/phoneMask"
    import {minLength, required} from "@utils/validators";
    import useTemplate from "@use/native/template";
    import useQuizCreate from "@use/data/quiz/create";

    export default {
        name: "Result",
        props: {
            title: {
                type: String,
                default: ''
            },
            text: {
                type: String,
                default: ''
            },
            questions: {
                type: Array,
                default: null
            },
            id: {
                type: Number,
                default: null
            },
            answers: {
                type: Array,
                default: null
            },
        },
        components: {
            "v-form": Form,
            "v-thanks": Thanks,
        },
        setup(props, {emit}) {
            let send = ref(false);
            let progress = ref(false);

            let thanks = {
                title: "Спасибо что обратились к нам!",
                description: "Мы свяжемся с вами для уточнения деталей"
            }

            let fields = {
                name: {
                    name: 'name',
                    label: 'Ваше имя',
                    value: '',
                    type: 'text',
                    clear: true,
                },
                phone: {
                    name: 'phone',
                    label: 'Телефон',
                    value: '',
                    type: 'tel',
                    required: true,
                    clear: true,
                    mask: usePhoneMask,
                    validators: {
                        required: {
                            validator: required,
                            message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                        },
                        minLength: {
                            validator: minLength(9),
                            pattern: '',
                            value: 9,
                            message: useTemplate`${'label'} - Слишком короткое поле ${'length'} ${'value'}`,
                        },
                    },
                },
            }

            const form = useForm({
                captcha: true,
                title: null,
                description: null,
                field: fields
            })

            async function submitHandler() {
                let ctx = {};

                Object.keys(form.fields ?? {}).map((key) => {
                    ctx[key] = form.fields[key].value
                })

                ctx.answer = props.answers;
                ctx.id = props.id;

                const {fetchData} = useQuizCreate(ctx);
                let data = await fetchData();

                if (data) {
                    send.value = true;
                    setTimeout(() => {
                        if (send.value === true) {
                            send.value = false;
                            emit('onResult');
                        }
                    }, 5000);
                }
            }

            return {
                send,
                form,
                fields,
                thanks,
                progress,

                submitHandler
            }
        },
    }
</script>

<style lang="less">
    .quiz-result {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 360px;
        margin: auto;

        &__text {
            line-height: 24px;
            &,
            p {
                font-size: var(--h5);
            }
        }

        &__info,
        &__form {
            /*max-width: 360px;*/
            padding: 20px;
        }

        &___form {

        }
    }
</style>
