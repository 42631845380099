export default function useSwitch(triggers, callback, cls = null, activate = 0) {
    let active = null;
    if (triggers.length) {
        for (const i in [...triggers]) {
            const index = parseInt(i),
                item = triggers[index];

            if (cls) {
                let trigger = triggers[activate];
                trigger.classList.add(cls);
                active = trigger;
            }

            item.addEventListener('click', () => {
                if (cls) {
                    if (active) {
                        active.classList.remove(cls);
                    }

                    item.classList.add(cls);
                    active = item;
                }

                callback(item, index);
            })
        }
    }

    function change(index) {
        const item = triggers[index];

        if (cls) {
            if (active) {
                active.classList.remove(cls);
            }

            item.classList.add(cls);
            active = item;
        }
    }

    return {change}
}
