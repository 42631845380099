<template>
    <transition name="shelf">
        <section class="shelf" v-if="isShow">
            <div class="shelf__wrapper">
                <!--                <div class="shelf__container">-->
                <header class="shelf__header">
                    <div class="shelf__search-btn search__btn" @click="onShowSearch"></div>

                    <transition name="slide">
                        <div v-if="isBack" class="shelf__back" @click="onBack"><span>Назад</span></div>
                    </transition>

                    <transition name="slide">
                        <v-close v-if="!isBack" class="shelf__close" @click="onClose"></v-close>
                    </transition>

                    <transition name="slide-right" mode="out-in">
                        <a v-if="getName.name"
                           :href="getName.url"
                           :key="getName"
                           class="shelf__title"><span>{{getName.name}}</span></a>
                    </transition>
                </header>

                <main class="shelf__content">
                    <div class="shelf__screen">
                        <ul class="shelf__list">
                            <li class="shelf__item shelf__phone callibri_phone"><a :href="phoneLink">{{phone}}</a></li>
                            <li class="shelf__item shelf__address" v-html="address"></li>

                            <li v-if="schedule_holy_days" class="shelf__item shelf__schedule shelf__schedule_holy-days feature">
                                <span class="feature__label">График работы в праздники</span>
                                <span v-html="schedule_holy_days" class="feature__value"></span>
                            </li>
                            <li v-else class="shelf__item shelf__schedule feature">
                                <span class="feature__label">График работы</span>
                                <span v-html="schedule" class="feature__value"></span>
                            </li>

                            <li class="shelf__item shelf__item_main"><a href="/">Главная</a></li>
                            <li class="shelf__item"
                                :class="{'shelf__item_drop': item.children, 'shelf__item_active': activeItem(item)}"
                                v-for="item in items"
                                :key="item"
                                @click="onChange($event, item)"
                            >
                                <a :href="item.url">{{item.name}}</a>
                            </li>
                        </ul>
<!--                        <div class="">{{getActiveItems}}</div>-->
<!--                        <div class="">{{findActive(items)}}</div>-->
                    </div>

                    <transition-group name="slide" mode="out-in">
                        <div v-for="screen in screens" :key="screen" class="shelf__screen shelf__screen_child">
                            <ul class="shelf__list">
                                <li class="shelf__item"
                                    v-for="item in screen.children"
                                    :class="{'shelf__item_drop': item.children, 'shelf__item_active': activeItem(item)}"
                                    :key="item"
                                    @click="onChange($event, item)"
                                >
                                    <a :href="item.url">{{item.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </transition-group>

                    <transition name="slide" mode="out-in">
                        <div v-if="search" class="shelf__screen shelf__screen_search">
                            <v-search
                                :q="q"
                                :drop="false"
                            ></v-search>
                        </div>
                    </transition>
                </main>

                <footer class="shelf__footer"></footer>
                <!--                </div>-->
            </div>
            <v-button class="">Записаться</v-button>
            <div class="shelf__overlay" v-show="isShow" @click="onClose"></div>
        </section>
    </transition>
</template>

<script>
    import {computed, ref, reactive, onMounted, onUnmounted, watchEffect} from "vue";
    import {useStore} from 'vuex'

    import Close from "@components/ui/Close";
    import Button from '@components/ui/forms/Button'
    import Search from '@components/search/Search'
    import UseGetURLParams from "@use/native/getURLParam";
    import {getPhoneLink} from "@utils/string";

    export default {
        name: "Shelf",
        components: {
            'v-close': Close,
            'v-button': Button,
            'v-search': Search
        },
        props: {
            phone: {
                type: String,
                default: '+7 (861) 298 34 68'
            },
            address: {
                type: String,
                default: 'г. Краснодар, ул. Красноармейская, 116'
            },
            schedule_holy_days: {
                type: String,
            },
            schedule: {
                type: String,
            },
        },
        setup(props) {
            const store = useStore()

            let path = window.location.pathname,
                pathList = path.split('/'),
                rootLevel = `/${pathList[1]}`

            let items = computed(() => store.getters['shelf/getItems'], ),
                step = ref(0),
                screens = reactive([]),

                back = ref(false),
                search = ref(false),
                title = ref(''),
                q = ref('');

            q.value = UseGetURLParams('q')
            onMounted(() => {
                document.addEventListener("keydown", onKeyDown);
                store.dispatch('shelf/getItem');
            })

            onUnmounted(() => {
                document.removeEventListener("keydown", onKeyDown)
            })

            let phoneLink = computed(() => `tel:${getPhoneLink(props.phone.trim())}`)
            let isShow = computed(() => store.getters['shelf/isShow'])
            let loaded = computed(() => store.getters['shelf/loaded'])
            let isBack = computed(() => search.value || back.value)

            let getName = computed(() => {
                let url = '',
                    name = '';

                if (search.value){
                    url = '/search'
                    name = 'Поиск'
                }else{
                    url = screens[screens.length-1] ? screens[screens.length-1].url : null
                    name = screens[screens.length-1] ? screens[screens.length-1].name : null
                }

                return {
                    'url': url,
                    'name': name,
                }
            })

            watchEffect(() => {
                    if (isShow.value) {
                        return document.body.style.overflow = "hidden"
                    } else {
                        return document.body.style.overflow = null
                    }
                }
            )

            function activeItem(item) {
                if (item.url === path) {
                    return true
                }

                if (item.url === rootLevel) {
                    return true
                }

                return item.active || false;
            }

            function onKeyDown(e) {
                if (isShow && e.keyCode === 27) {
                    onClose();
                }
            }

            function onClose() {
                reset();
                store.dispatch('shelf/show', false);
            }

            function onShowSearch() {
                step.value = step.value > 0 ? 1 : step.value + 1;
                search.value = true;
            }

            function onBack() {
                screens.pop();
                step.value = step.value - 1;
                back.value = step.value !== 0;
                search.value = false;
            }

            function reset() {
                screens.length = 0;

                step.value = 0;
                back.value = false;
                search.value = false;
            }

            function onChange(e, item) {
                e.preventDefault()
                if (item.children) {
                    back.value = true;
                    screens[step.value] = {
                        'name': item.name,
                        'children': item.children ? item.children: null,
                        'url': item.url,
                    };

                    step.value = step.value + 1;
                } else {
                    window.location.href = item.url;
                }
            }

            return {
                onChange,
                onClose,
                onBack,
                onShowSearch,
                isShow,
                isBack,
                loaded,

                activeItem,
                phoneLink,
                // getActiveItems,
                // findActive,
                getName,

                search,
                q,
                back,

                title,
                items,
                screens,
                step,
            }
        },
    }

</script>

<style></style>
