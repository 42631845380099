// import * as Vue from 'vue'
import {createApp} from 'vue'
import {store} from './store'

import Search from "@components/search/Search";
import Shelf from "@components/shelf/Shelf";
import Ticket from "@components/relations/Ticket";
import Review from "@components/relations/Review";
import Ordering from "@components/feedback/Ordering"
import Visually from "@components/visually/Visually"

import ModalLeap from "@modules/ui/modal";
import sidebarScrollNav from "@modules/nav/sidebarScrollNav";
import breadcrumbsNav from "@modules/nav/breadcrumbs";

import SliderLeap from "@modules/ui/sliders/slider";
import SliderSimpleLeap from "@modules/ui/sliders/sliderSimple";

import Gallery from "@modules/content/gallery";
import Video from "@modules/content/video";
import Preview from "@modules/content/preview";
import mapsCreator from "@modules/information/maps";
import {clickOutside} from "@/directive/clickOutSide";
import UseGetURLParams from "@use/native/getURLParam";
import CatalogNavigation from "@components/navigation/CatalogNavigation";
import Quiz from "@components/quiz/Quiz";
// import useSwitch from "@use/native/switch";

// import useRegisterServiceWorker from "@use/native/registerServiceWorker";

window.addEventListener('load', async () => {
    // await useRegisterServiceWorker()

    let $body = document.querySelector('body');
    let $main = document.querySelector('main.layout');
    let $header = $body.querySelector('#header');
    // let $footer = $body.querySelector('#footer');

    let headerHeight = $header.clientHeight;
    let width = null;
    // let height = null;

    let $content = $main.querySelector('#content');
    let $sidebar = $main.querySelector('#sidebar');
    let sidebar = false;
    let resizeTimeOutId = null;

    setTimeout(() => window.yaCounter55618591.reachGoal('time_310'), 310000);

    function resizeHandler() {
        width = window.innerWidth;
        // height = window.innerHeight;
        if ($content && $sidebar) {
            if (width > 960) {
                if (!sidebar) {
                    sidebar = true;
                    sidebarScrollNav($sidebar, $content);
                }
            }
        }
    }

    resizeHandler();
    window.addEventListener('resize', () => {
        if (resizeTimeOutId) {
            clearTimeout(resizeTimeOutId);
        }
        resizeTimeOutId = setTimeout(() => resizeHandler(), 150);
    })

    function scrollHandler() {
        if (window.pageYOffset > headerHeight) {
            $header.classList.add('header_fixed');
        } else {
            $header.classList.remove('header_fixed');
        }
    }

    document.addEventListener('scroll', scrollHandler)
    scrollHandler();

    /**Navigation*/
    let $navBtn = $header.querySelector('#nav-btn');
    let $shelf = $body.querySelector('#shelf');

    if ($navBtn && $shelf) {
        let shelfCreate = false;
        $navBtn.addEventListener('click', () => {
            if (!shelfCreate) {
                let dataset = $shelf.dataset ?? {}
                shelfCreate = true;

                let $phone = $header.querySelector('.header__phone');
                if ($phone) {
                    dataset.phone = $phone.innerHTML;
                }

                createApp(Shelf, {...dataset})
                    .use(store)
                    .directive("click-outside", clickOutside)
                    .mount($shelf);
            }

            store.dispatch('shelf/show', true);
        })
    }

    function activateAsyncItem(item, dataset) {
        if (!item?.active) {
            item.active = true;
            store.dispatch('navigation/getItems', {
                id: dataset?.id,
                url: dataset?.url
            })

            if (dataset?.url) {
                createApp(CatalogNavigation, {...dataset})
                    .use(store)
                    .mount(item);
            }
        }
    }

    /**Catalog*/
    let $navigationAsyncList = $header.querySelectorAll('.nav__list_async');
    if ($navigationAsyncList) {
        for (const $item of [...$navigationAsyncList]) {
            let dataset = $item.dataset ?? {}
            $item.parentNode.addEventListener('mouseenter', () => {
                activateAsyncItem($item, dataset)
            }, {once: true})

            $item.parentNode.addEventListener('mousemove', () => {
                activateAsyncItem($item, dataset)
            }, {once: true})
        }
    }

    let $breadcrumbs = $main.querySelector('.breadcrumbs');
    if ($breadcrumbs) {
        breadcrumbsNav($breadcrumbs);
    }

    /**Content*/
    let $videos = document.querySelectorAll('.video');
    if ($videos.length) {
        for (const $item of $videos) {
            const $videoModal = new ModalLeap();
            $videoModal.setOption({
                maxWidth: 640,
                maxHeight: 360,
            });

            let $content = document.createElement('div');
            $content.classList.add('youtube');
            $item.appendChild($content);
            const video = new Video($item, $content);

            $item.addEventListener('click', () => {
                $videoModal.createModal($content);
                video.createVideo($content);
            });
        }
    }

    let $actions = document.querySelector('#actions');
    if ($actions) {
        let $actionsList = $actions.querySelector('.actions__list');
        let $actionsItem = $actionsList.children;
        if ($actionsList.children.length > 1) {
            $actionsList.add('actions__list_slide');
            new SliderSimpleLeap($actionsList, {items: $actionsItem});
        }
    }

    /**Slider*/
    let $splash = document.querySelector('#splash-main');
    if ($splash) {
        let $sliderSplash = $splash.querySelector('.slider');
        // let $sliderNavigation = $splash.querySelector('.splash__list');

        // const sliderNavigation = new SliderLeap($sliderNavigation, {
        //     loop: true,
        //     points: false,
        //     arrows: false,
        //     response: {
        //         0: {
        //             items: 2,
        //             direction: 'horizontal',
        //         },
        //         640: {
        //             items: 3,
        //             direction: 'horizontal',
        //         },
        //         960: {
        //             items: 4,
        //             direction: 'vertical',
        //         }
        //     }
        // })

        // const sliderSplash = new SliderLeap($sliderSplash, {
        new SliderLeap($sliderSplash, {
            loop: true,
            // points: false,
            response: {
                0: {
                    arrows: false,
                },
                960: {
                    arrows: true,
                }
            },
            // onChange: (self) => {
            //     sliderNavigation.change(self.step);
            // },
            // onBuild: () => {
            //     useSwitch(sliderNavigation.getChild(), (item, index) => {
            //         sliderNavigation.change(index - sliderNavigation.getView())
            //         sliderSplash.change(index - sliderNavigation.getView());
            //     });
            // }
        })
    }


    /*Ordering*/
    let $orderButtons = document.querySelectorAll('.btn_order');
    if ($orderButtons.length) {

        const $modal = new ModalLeap();
        $modal.setOption({
            maxWidth: 520,
        });

        for (const $item of $orderButtons) {
            let content = document.createElement('div');
            let orderingApp = null;

            $item.addEventListener('click', () => {
                store.dispatch('ordering/getData', $item.dataset);
                if (!orderingApp) {
                    orderingApp = createApp(Ordering, {
                        dataset: $item.dataset,
                        title: 'Записаться на прием',
                        class: 'ordering_modal',
                        modal: true,
                    })
                        .use(store)
                        .mount(content);
                }

                $modal.createModal(content);
            });
        }
    }

    let $orderingSpecialist = $body.querySelector('#ordering');
    if ($orderingSpecialist) {
        createApp(Ordering, {
            dataset: $orderingSpecialist.dataset,
        })
            .use(store)
            .mount($orderingSpecialist);
    }

    let $visuallyBtn = $body.querySelector('#visually-btn');
    if ($visuallyBtn) {
        $visuallyBtn.addEventListener('click', () => {
            if (store.getters['information/isVisually']) {
                store.dispatch('information/visually', false);
                $visuallyBtn.classList.remove('visually-btn_on');
            } else {
                store.dispatch('information/visually', true);
                $visuallyBtn.classList.add('visually-btn_on');
            }
        })
    }

    let $visuallyPanel = $body.querySelector('#visually-panel');
    if ($visuallyPanel) {
        createApp(Visually, {})
            .use(store)
            .mount($visuallyPanel);
    }

    /**Quiz*/
    let $quiz = document.querySelectorAll('.quiz-app');
    if ($quiz.length) {
        for (const $item of $quiz) {
            const data = $item.dataset ?? {}
            createApp(Quiz, data)
                .use(store)
                .mount($item);
        }
    }

    /**Relations*/
    let $previews = document.querySelectorAll('.preview');
    if ($previews.length) {
        for (const item of $previews) {
            new Preview(item)
        }
    }

    let $sidebarCollapse = document.querySelector('.sidebar_collapse');
    if ($sidebarCollapse) {
        new Preview($sidebarCollapse, 'sidebar', 'услуги');
    }

    let $reviewItemScans = document.querySelectorAll('.review-item__scan');
    if ($reviewItemScans.length) {
        for (const item of $reviewItemScans) {
            new ModalLeap(item, {
                type: 'image'
            })
        }
    }

    let $reviewScans = document.querySelectorAll('.review__scan');
    if ($reviewScans.length) {
        for (const item of $reviewScans) {
            new ModalLeap(item, {
                type: 'image'
            })
        }
    }

    let $reviewButtons = document.querySelector('#review-btn');
    if ($reviewButtons) {
        const $modalReview = new ModalLeap();
        $modalReview.setOption({
            maxWidth: 520,
        });

        let reviewContent = document.createElement('div');
        let reviewApp = null;

        $reviewButtons.addEventListener('click', () => {
            if (!reviewApp) {
                reviewApp = createApp(Review, {
                    dataset: $reviewButtons.dataset,
                    title: 'Написать отзыв',
                    class: 'review_modal',
                })
                    .use(store)
                    .mount(reviewContent);
            }

            $modalReview.createModal(reviewContent);
        });
    }

    let $ticketButtons = document.querySelectorAll('.ticket-btn');
    if ($ticketButtons) {
        const $modalTicket = new ModalLeap();
        $modalTicket.setOption({maxWidth: 520});

        for (const $item of $ticketButtons) {
            let ticketContent = document.createElement('div');
            let ticketApp = null;

            $item.addEventListener('click', () => {
                if (!ticketApp) {
                    ticketApp = createApp(Ticket, {
                        dataset: $item.dataset,
                        title: 'Задать вопрос',
                        class: 'ticket_modal',
                        modal: true,
                    }).use(store).mount(ticketContent);
                }

                $modalTicket.createModal(ticketContent);
            });
        }
    }

    /**Gallery*/
    let $gallery = $body.querySelector('#gallery');
    if ($gallery) {
        new Gallery($gallery);

        // let gallery = new SliderSimpleLeap(item);
        // if ($gallery.dataset.slug) {
        //     createApp(Gallery, {
        //         slug: $gallery.dataset.slug,
        //         size: $gallery.dataset.size
        //     }).mount($gallery);
        // }
    }

    /**Search*/
    let $search = $header.querySelector('#search');
    let $searchOnPage = $body.querySelector('#search-on-page');
    if ($search || $searchOnPage) {
        let searchOptions = {
            q: UseGetURLParams('q')
        }

        createApp(Search, searchOptions)
            .use(store)
            .directive("click-outside", clickOutside)
            .mount($search);

        createApp(Search, searchOptions)
            .use(store)
            .directive("click-outside", clickOutside)
            .mount($searchOnPage);
    }

    /**Information*/
    mapsCreator($main);
})

window.addEventListener('unload', () => {
    console.log('unload');
})
