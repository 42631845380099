<template>
    <v-feedback
        :fields="fields"
        :dataset="dataset"
        :title="title"
        :thanks="thanks"
        submitLabel="Отправить отзыв"
        :fetch="useReview"
    ></v-feedback>
</template>

<script>
    import Feedback from "@components/feedback/Feedback";

    import {email, required} from "@utils/validators";
    import useTemplate from "@use/native/template";
    import useReview from "@use/data/relations/review";

    export default {
        name: "Review",
        components: {
            "v-feedback": Feedback,
        },
        props: {
            title: {
                type: String
            },
            dataset: {
                type: Object
            },
        },
        setup(){

            let thanks = {
                title: "Спасибо что обратились к нам!",
                description: "Как только ответ будет опубликован вы получите оповещение на указанный Email"
            }

            let fields = {
                name: {
                    name: 'name',
                    label: 'Заголовок отзывы',
                    value: '',
                    required: true,
                    type: 'text',
                    clear: true,
                    validators: {
                        required: {
                            validator: required,
                                message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                        },
                    },
                },
                content: {
                    name: 'content',
                    label: 'Текст отзывы',
                    value: '',
                    type: 'textarea',
                    height: 160,
                    clear: true,
                },
                pro: {
                    name: 'pro',
                    label: 'Достоинства',
                    value: '',
                    type: 'textarea',
                    height: 120,
                    clear: true,
                },
                contra: {
                    name: 'contra',
                    label: 'Недостатки',
                    value: '',
                    type: 'textarea',
                    height: 120,
                    clear: true,
                },
                author: {
                    name: 'author',
                    label: 'Ваше имя',
                    value: '',
                    type: 'text',
                    clear: true,
                    help: 'Указав Email вы получите уведомление об ответе на вопрос',
                    // validators: {
                    //     required: {
                    //         validator: required,
                    //         message: useTemplate`${'label'} - Обязательное поле для заполнения`,
                    //     },
                    // },
                },
                email: {
                    name: 'email',
                    label: 'Email',
                    value: '',
                    type: 'email',
                    clear: true,
                    // help: 'Указав Email вы получите уведомление об ответе на вопрос',
                    validators: {
                        required: {
                            validator: email,
                                message: useTemplate`Не верный формат ${'label'}`,
                        },
                    },
                },
            }

            return {
                fields,
                thanks,
                useReview
            }
        }
    }
</script>

<style scoped>

</style>
