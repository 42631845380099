export default function useKey(options) {
    const _defaults = {
        // TOP: 38,
        // BOTTOM: 40,
        // LEFT: 37,
        // RIGHT: 39,
        // ESC: 27
    }

    let _options = Object.assign({}, _defaults, options)
    let callbacks = {}

    Object.keys(_options ?? {}).map((key) => {
        callbacks[key] = () => {};
    })

    function event(e) {
        Object.keys(_options ?? {}).map((key) => {
            if(e.keyCode === _options[key]) callbacks[key](key);
        })
    }

    return {
        callbacks,
        event
    }
}
