import {useCookies} from "vue3-cookies";
const {cookies} = useCookies();

const information = {
    namespaced: true,
    state: () => ({
        visually: false,
    }),
    getters: {
        isVisually: (state) => state.visually,
    },
    mutations: {
        VISUALLY(state, payload) {
            cookies.set('visually', payload ? 'on' : 'off');
            state.visually = payload;
        },
    },
    actions: {
        visually({commit}, payload) {
            commit('VISUALLY', payload);
        },
    },
}


export default information
