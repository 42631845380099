import Arrows from "@modules/ui/arrows";

import Element from "@modules/core/element";

import useTemplate from "@use/native/template";
import useDrag from "@use/native/drag";
import useKey from "@use/native/key";

// import {preloader} from "@templates/blocks";
import useSwitch from "@use/native/switch";

export default class ModalLeap {
    constructor(el = null, options = {}) {
        const _defaults = {
            target: null,
            type: 'html', // ajax, image, html
            maxWidth: '640', minHeight: '120',
            width: null, height: null,
            class: null,
            thumbs: false,
            url: false,

            beforeOpen: function () {
            },
            afterOpen: function () {
            },
            beforeClose: function () {
            },
            afterClose: function () {
            },
            beforeCreate: function () {
            },
            afterCreate: function () {
            },
            afterLoading: function () {
            },
            afterLoadingOnShow: function () {
            },
            errorLoading: function () {
            }
        }

        this.options = Object.assign({}, _defaults, options);
        this.$el = el || null;
        this.modals = [];
        this.modal = null;

        this.render();
    }

    render() {
        if (this.$el) {
            this.init();
        }
    }

    setOption(options) {
        this.options = Object.assign({}, this.options, options);
    }

    init() {
        switch (this.options.type) {
            case 'ajax':
                if (this.options.url === '') return;
                this.initAjax();
                break;
            case 'html':
                this.initHtml();
                break;
            case 'image':
                this.initGallery();
                break;
        }
    }

    initAjax() {

    }

    initHtml() {
        let href = this.$el.getAttribute('href'),
            content;

        if (href) {
            content = document.querySelector(href);
        } else {
            content = this.options.target;
        }

        this.$el.addEventListener('click', (e) => {
            e.preventDefault();
            this.createModal(content);
        });
    }

    initGallery() {
        this.$el.addEventListener('click', (e) => {
            console.log(e);
            e.preventDefault();
            const modal = new Modal(this.options);
            modal.addImage(this.$el);
        });
    }

    createModal(content) {
        if (content) {
            if (!this.modal) {
                this.modal = new Modal(this.options);
                this.modal.addHTML(content);
            } else {
                this.modal.open();
            }
        }
    }

    open() {
        this.options.beforeOpen();
        if (this.modal) {
            this.modal.open();
        }
    }

    close() {
        this.options.beforeClose();
        if (this.modal) {
            this.modal.close();
        }
    }
}

let modalId = 0,
    modals = [];

class Modal extends Element {
    constructor(options) {
        super();
        this._tpl = {
            modal: useTemplate`<section class="modal ${'cls'}" id="${'id'}">
                                    <div class="modal__container">
                                        <div class="modal__content">
                                                <div class="modal__close close"></div>
                                                ${'ass'}
                                        </div>
                                    </div>
                                    <div class="modal__overlay"></div>
                                </section>`,
            header: useTemplate`<header class="modal__header">${'content'}</header>`,
            body: useTemplate`<main class="modal__body">${'content'}</main>`,
            footer: useTemplate`<footer class="modal__footer">${'content'}</footer>`,
            image: useTemplate`<figure class="modal__image ${'cls'}">
                                    <img class="image" src="${'href'}" alt="${'alt'}"/>
                                </figure>`,
            thumbnails: useTemplate`<div class="modal__thumbnails"></div>`,
            thumbnail: useTemplate`<figure class="modal__thumbnail ${'cls'}">
                                        <img class="image" src="${'href'}" alt="${'alt'}"/>
                                    </figure>`,
        }

        this.options = Object.assign({}, this._defaults, options);

        this.$body = null;
        this.$content = null;
        this.$overlay = null;
        this.$close = null;
        this.$modal = null;
        this.$parentTarget = null;
    }

    render() {
        this.id = modalId;
        this.selector = 'modal-' + this.id;

        this.$body = document.querySelector('body');
        this.$body.insertAdjacentHTML('afterbegin', this._tpl.modal({
            cls: 'class',
            id: this.selector,
        }));

        this.$modal = this.$body.querySelector(`#${this.selector}`);
        this.$modal.style.zIndex = 10000 + modalId;

        this.$content = this.$modal.querySelector(`.modal__content`);
        this.$overlay = this.$modal.querySelector(`.modal__overlay`);
        this.$container = this.$modal.querySelector(`.modal__container`);
        this.$close = this.$modal.querySelector(`.close`);

        this.$container.style.maxWidth = `${this.options.maxWidth}px`;
        if (this.options.width) {
            this.$content.style.width = `${this.options.width}px`;
        }

        if (this.options.height) {
            this.$content.style.height = `${this.options.height}px`;
        }

        modals.push(this);
        this.open();
    }

    addHTML(target) {
        // console.log(this.$content, this.isCreate);
        if (!this.isCreate()) {
            this.render();
        }

        this.$target = target;
        this.$parentTarget = this.$target.parentElement;
        this.$content.appendChild(this.$target);

        let {callbacks: callbacksKey, event} = useKey({
            ESC: 27
        })

        this.onKeyDown = event;
        document.addEventListener('keydown', this.onKeyDown);

        callbacksKey.ESC = () => this.close();
    }

    isCreate() {
        return !!this.$content;
    }

    open() {
        if (this.$modal) {
            modalId++;

            this.$close.addEventListener('click', () => {
                this.close();
            });

            this.$modal.addEventListener('click', (e) => {
                if (e.target === this.$overlay || e.target === this.$container) {
                    this.close();
                }
            });

            document.body.style.overflow = "hidden";
            this.$modal.style.display = 'flex';
            this.$modal.classList.add('modal_enter');
        }

        this.options.afterOpen();
    }

    close() {
        modalId--;
        if (this.$target) {
            document.body.style.overflow = null;
            this.$modal.classList.add('modal_leave');

            document.removeEventListener('keydown', this.onKeyDown);

            setTimeout(() => {
                this.$modal.classList.remove('modal_enter', 'modal_leave');
                this.$modal.style.display = 'none';
            }, 500)
        }

        this.options.afterClose();
    }

    getGroup(target) {
        let rel = target.getAttribute('rel');
        return [...document.querySelectorAll(`[rel=${rel}]`)];
    }

    addImage(target) {
        this.render();

        this.step = 0;
        this.$target = target;

        let {callbacks: callbacksKey, event} = useKey({
            LEFT: 37,
            RIGHT: 39,
            ESC: 27
        })

        this.group = this.getGroup(this.$target);
        if (this.group.length > 1) {
            this.len = this.group.length;

            let {callbacks: callbacksDrag} = useDrag(this.$content);
            callbacksDrag.onSwipeRight = () => this.onPrev();
            callbacksDrag.onSwipeLeft = () => this.onNext();

            const {callbacks: callbacksArrow} = Arrows(this.$overlay);
            callbacksArrow.onPrev = () => this.onPrev();
            callbacksArrow.onNext = () => this.onNext();

            document.addEventListener('mousewheel', (e) => {
                this.onWheel(e);
            })

            callbacksKey.LEFT = () => this.onPrev();
            callbacksKey.RIGHT = () => this.onNext();

            this.addThumbnails(this.group);
        }

        this.onKeyDown = event;
        callbacksKey.ESC = () => this.close();
        document.addEventListener('keydown', this.onKeyDown);

        this.src = this.$target.dataset.img;
        this.title = this.$target.dataset.title;

        this.img = this.createImage(this.src);
        this.$content.appendChild(this.img);

        this.preloader = document.createElement("div");
        this.preloader.classList.add('preloader');
        this.$content.appendChild(this.preloader);
        this.preloader.style.display = 'flex';

        this.img.addEventListener("load", ()=>{
            this.preloader.style.display = 'none';
        }, false);

        // this.$content.appendChild(this.preloader);

        // this.preloader = preloader();
        // this.$content.insertAdjacentHTML('beforeend', this.preloader);
        // console.log(this.preloader);
    }

    createImage(src) {
        let img = new Image();
        img.src = src;
        return img;
    }

    addThumbnails(group) {
        this.$container.insertAdjacentHTML('beforeend',this._tpl.thumbnails())
        this.$thumbnails = this.$container.querySelector('.modal__thumbnails');

        group.forEach((item) => {
            let image = this.createImage(item.dataset.thumbnail);
            image.classList.add('modal__thumbnail');
            this.$thumbnails.appendChild(image);
        })

        this.$images = [...this.$thumbnails.querySelectorAll('img')];

        let {change} = useSwitch(this.$images, (item) => {
            this.changeImage(this.$images.indexOf(item));
        }, 'modal__thumbnail_active');

        this.onSwitchImage = change;
    }

    loadImage() {
        // console.log('load');
    }

    changeImage(step) {
        let _step = step;
        if (_step !== this.step) {
            if (_step < 0) {
                _step = this.len - 1;
            }

            if (_step >= this.len) {
                _step = 0;
            }

            this.onSwitchImage(_step);
            this.step = _step;

            this.preloader.style.display = 'flex';
        }

        this.img.src = this.group[this.step].dataset.img;
    }

    onPrev() {
        console.log('prev');
        this.changeImage(this.step - 1);
    }

    onNext() {
        console.log('next');
        this.changeImage(this.step + 1);
    }

    onWheel(e) {
        this.changeImage(parseInt(this.step + (e.wheelDelta / 132)));
    }
}

