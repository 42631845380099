export default class Preview {
    constructor(el, prefix='preview', text='') {
        this.$el = el;
        this.text = text;
        this.prefix = prefix;

        this.$trigger = document.createElement('div');
        this.$trigger.classList.add(`${prefix}__trigger`);
        this.$trigger.innerText = `Показать ещё ${this.text}`;
        this.$el.appendChild(this.$trigger);

        this.$trigger.addEventListener('click', () => {
            this.onClick();
        })
    }

    onClick() {
        if (this.$el.classList.contains(`${this.prefix}_open`)) {
            this.$trigger.innerText = `Показать ещё ${this.text}`;
        } else {
            this.$trigger.innerText = `Скрыть ${this.text}`;
        }
        this.$el.classList.toggle(`${this.prefix}_open`);
    }
}
