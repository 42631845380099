<template>
    <div class="reception">
        <v-datepicker class="reception__dates"
                      :dates="schedules"
                      @onSelect="onChange"
        ></v-datepicker>

        <v-time-list
            class="reception__times"
            label="Время"
            :list="times"
        ></v-time-list>
        <div class="reception__date"></div>
    </div>
</template>

<script>
    import Datepicker from "@components/ui/Datepicker";
    import TimeList from "@components/ui/TimeList";

    export default {
        name: "Reception",
        components: {
            "v-datepicker": Datepicker,
            "v-time-list": TimeList,
        },
        setup() {
            let schedules = [
                {
                    "id": "2005",
                    "build_id": "1",
                    "build_name": "Корпус 1",
                    "room": "Кабинет № 2",
                    "address": "ул. Космонавтов, 41",
                    "kind": "0",
                    "date": "06.04.2021",
                    "start": "08:00",
                    "end": "09:00",
                    "intervals": [
                        {
                            "start": "08:00",
                            "end": "08:20",
                            "free": true
                        },
                        {
                            "start": "08:20",
                            "end": "08:40",
                            "free": true
                        },
                        {
                            "start": "08:40",
                            "end": "09:00",
                            "free": false
                        }
                    ]
                }, {
                    "id": "2006",
                    "build_id": "1",
                    "build_name": "Корпус 1",
                    "room": "Кабинет № 2",
                    "address": "ул. Космонавтов, 41",
                    "kind": "0",
                    "date": "06.04.2021",
                    "start": "08:00",
                    "end": "09:00",
                    "intervals": [
                        {
                            "start": "08:00",
                            "end": "08:20",
                            "free": true
                        },
                        {
                            "start": "08:20",
                            "end": "08:40",
                            "free": true
                        },
                        {
                            "start": "08:40",
                            "end": "09:00",
                            "free": false
                        }
                    ]
                }
            ]

            let times = [
                {
                    'start': '12:30',
                    'end': '12:50',
                    'free': true
                },
                {
                    'start': '13:30',
                    'end': '13:50',
                    'free': false
                },
                {
                    'start': '14:00',
                    'end': '14:20',
                    'free': true
                },
                {
                    'start': '14:30',
                    'end': '14:50',
                    'free': true
                },
            ]

            function onChange(value) {
                console.log(value);
            }

            return {
                times,
                schedules,
                onChange
            }
        }
    }
</script>

<style lang="less">
    .reception {
        &__dates {
            margin-bottom: 1.5rem;
        }

        &__times {

        }

        &__date {

        }
    }
</style>
