import useGoogleMaps from "@use/native/googleMaps";

export default function mapsCreator($selector) {
    if ('mapsConfig' in window) {
        for (const item of window.mapsConfig) {
            let $map = $selector.querySelector(item.selector);
            if ($map) {
                new useGoogleMaps($map, item.options);
            }
        }
    }
}
