<template>
    <div class="visually-panel" v-if="show">
        <div class="visually-panel__container container">
            <div class="visually-panel__sizes">
                <div class="visually-panel__label">Размер шрифта</div>
                <div class="visually-panel__list">
                    <div v-for="(item, index) in sizes"
                         class="visually-panel__size"
                         :class="[`visually-panel__size_${item.value}`, {'visually-panel__size_active': item.value===size}]"
                         @click="sizeHandler(item)"
                         :key="index">A
                    </div>
                </div>
            </div>

            <div class="visually-panel__colors">
                <div class="visually-panel__label">Цвет сайта</div>
                <div class="visually-panel__list">
                    <div v-for="(item, index) in colors"
                         class="visually-panel__color"
                         :class="[`visually-panel__color_${item.value}`, {'visually-panel__color_active': item.value===color}]"
                         @click="colorHandler(item)"
                         :key="index">Ц
                    </div>
                </div>
            </div>

            <div class="visually-panel__images">
                <div class="visually-panel__label">Изображения</div>
                <div class="visually-panel__list visually-panel__tabs">
                    <div v-for="(item, index) in images"
                         class="visually-panel__image"
                         :class="{'visually-panel__image_active': item.value===image}"
                         @click="imageHandler(item)"
                         :key="index">{{ item.label }}
                    </div>
                </div>
            </div>

            <div class="visually-panel__close btn btn_secondary"
                 @click="closeHandler"
            >Обычная версия сайта
            </div>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useCookies} from "vue3-cookies";

export default {
    name: "Visually",
    setup() {
        const store = useStore();
        const {cookies} = useCookies();

        const show = computed(() => store.getters['information/isVisually']);

        const visually = cookies.get('visually')
        if (visually === 'on') {
            store.dispatch('information/visually', true);
        }

        const size = ref(cookies.get('visually_size') ?? 'md');
        const color = ref(cookies.get('visually_color') ?? 'wb');
        const image = ref(cookies.get('visually_image') ?? 'on');

        if (visually === 'on') {
            store.dispatch('information/visually', true);
        }

        const sizes = [
            {
                value: 'sm',
                label: 'обычный'
            },
            {
                value: 'md',
                label: 'большой'
            },
            {
                value: 'lg',
                label: 'огромный'
            }
        ]

        const colors = [
            {
                value: 'st',
                label: 'обычный'
            },
            {
                value: 'wb',
                label: 'черным по белому'
            },
            {
                value: 'bw',
                label: 'белым по черному'
            },
            {
                value: 'bg',
                label: 'зеленым по темно-коричневому'
            }
        ]

        const images = [
            {
                value: 'on',
                name: 'включены',
                label: 'Вкл'
            },
            {
                value: 'off',
                name: 'отключены',
                label: 'Отк'
            },
        ]

        const sizeHandler = (item) => {
            size.value = item.value;
            change();
        }

        const colorHandler = (item) => {
            color.value = item.value;
            change();
        }

        const imageHandler = (item) => {
            image.value = item.value;
            change();
        }

        const closeHandler = () => {
            // store.dispatch('information/visually', false);
            store.dispatch('information/visually', false);
            cookies.set('visually', 'off');
            cookies.remove('visually_size');
            cookies.remove('visually_color');
            cookies.remove('visually_image');
            document.documentElement.classList.remove(`visually_${size.value}`)
            document.documentElement.classList.remove(`visually_${color.value}`)
            document.documentElement.classList.remove(`visually_image`)
            // change();
        }

        const change = () => {
            if (size.value) cookies.set('visually_size', size.value);
            if (color.value) cookies.set('visually_color', color.value);
            cookies.set('visually', show.value ? 'on' : 'off');
            cookies.set('visually_image', image.value);

            if (image.value === 'off') {
                document.documentElement.classList.add(`visually_image`)
            } else {
                document.documentElement.classList.remove(`visually_image`)
            }
        }

        const watcher = (value, old) => {
            document.documentElement.classList.remove(`visually_${old}`)
            document.documentElement.classList.add(`visually_${value}`)
        }

        watch(size, (value, old) => watcher(value, old))
        watch(color, (value, old) => watcher(value, old))
        watch(() => show.value, (value) => {
            if (value) {
                window.scroll({top: 0})
                if (size.value) cookies.set('visually_size', size.value);
                if (color.value) cookies.set('visually_color', color.value);
                document.documentElement.classList.add(`visually_${size.value}`)
                document.documentElement.classList.add(`visually_${color.value}`)
            } else {
                closeHandler();
            }
        })

        return {
            show,
            color,
            size,
            image,
            sizes,
            colors,
            images,
            sizeHandler,
            colorHandler,
            imageHandler,
            closeHandler
        }
    }
}
</script>

<style lang="less">


.visually-panel {
    width: 100%;
    //height: 80px;
    overflow: hidden;
    padding: 10px 0;
    box-sizing: border-box;
    background: var(--ex-lt-gray);
    box-shadow: var(--shadow-base);
    transition: all .3s ease;

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__list {
        display: flex;
    }

    &__images {
        @media screen and (max-width: 960px) {
            justify-content: flex-start;
        }
    }

    &__label {
        //.h6();
        font-size: 14px;
        color: var(--gray);
        margin-bottom: 5px;
    }

    &__sizes,
    &__colors{
        @media screen and (max-width: 960px) {
            width: 50%;
            margin-bottom: 10px;
        }
    }

    &__size,
    &__color,
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        font-size: 16px;

        font-weight: bold;
        transition: all .3s ease;
        cursor: pointer;
        line-height: 32px;
    }

    &__size,
    &__color {
        border-radius: var(--border-radius-half);
        border: 2px solid var(--lt-gray);
        margin-right: 4px;

        &:hover,
        &_active {
            border: 2px solid var(--primary);
        }
    }

    &__size {
        &_sm {
            //.h6();
            font-size: 16px;
        }

        &_md {
            font-size: 18px;
            //.h5();
        }

        &_lg {
            font-size: 21px;
            //.h4();
        }
    }

    &__color {
        font-size: 16px;

        &_wb {
            background: var(--white);
            color: var(--black);
        }

        &_bw {
            background: var(--black);
            color: var(--white);
        }

        &_bg {
            background: #79270a;
            color: #9fff21;
        }
    }

    &__tabs {
        border: 2px solid var(--lt-gray);
        border-radius: var(--border-radius);
        overflow: hidden;
    }

    &__image {
        width: auto;
        padding: 0 10px;

        &:hover,
        &_active {
            background: var(--primary);
        }
    }

    &__close {
        font-weight: bold;
        align-self: center;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        display: inline-block;
        padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 16px;

        @media screen and (max-width: 960px) {
            font-size: 14px;
        }
    }
}
</style>





