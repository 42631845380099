import useNavMain from "@use/data/core/nav_main";
import {reactive} from "vue";


const shelf = {
    namespaced: true,
    state: () => ({
        show: false,
        social: [],
        contacts: [],
        items: null,
        loaded: true
    }),
    getters: {
        isShow: (state) => {
            return state.show
        },
        getItems: (state) => {
            return state.items
        }
    },
    mutations: {
        SHOW(state, payload) {
            state.show = payload
        },
        SET_ITEMS(state, payload) {
            if (!state.items) {
                findActivePath(payload)
                state.items = payload;
            }
        }
    },
    actions: {
        show({commit}, payload) {
            commit('SHOW', payload);
        },
        async getItem({commit}) {
            const {fetchData} = useNavMain();
            let data = await fetchData();

            if (data) {
                commit('SET_ITEMS', data);
            }
        }
    },
    modules: {}
}


export default shelf

function findActivePath(nodes) {
    let path = window.location.pathname,
        pathList = path.split('/'),
        rootLevel = `/${pathList[1]}`

    const list = reactive([]);

    function findItem(node) {
        if (node.children) {
            for (const item of node.children) {
                if (item.children) {
                    if (item.url === path) {
                        list.push(item)
                    }
                    findItem(item)
                } else {
                    if (item.url === path) {
                        list.push(node)
                        list.push(item)
                    }
                }
            }
        }
    }

    for (const item of nodes) {
        if (item.url === rootLevel) {
            list.push(item)
            findItem(item);
            break;
        }else{
            findItem(item);
        }
    }

    for (const item of list) {
        item.active = true;
    }
}


