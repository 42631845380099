export default function Arrows(el) {
    const tpl = {
        arrows: `<div class="arrows">
                    <div class="arrows__prev"></div>
                    <div class="arrows__next"></div>
                </div>`,
    }

    el.insertAdjacentHTML('afterbegin', tpl.arrows);
    const $arrows = el.querySelector('.arrows');
    const $prev = $arrows.querySelector('.arrows__prev');
    const $next = $arrows.querySelector('.arrows__next');

    $prev.addEventListener('click', () => {
        callbacks.onPrev();
    })

    $next.addEventListener('click', () => {
        callbacks.onNext();
    })

    const callbacks = {
        onPrev: function () {
        },
        onNext: function () {
        },
    }

    return {
        callbacks,
        $arrows
    }
}
