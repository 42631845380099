export default class Element {
    constructor(options) {
        this._defaults = {};
        this._el = null;
        this._display = true;
        this.options = Object.assign({}, this._defaults, options);
    }

    get display() {
        return this._display;
    }

    set display(value) {
        if (this._el) {
            if (value) {
                this._el.style.display = 'auto';
            } else {
                this._el.style.display = 'none';
            }
        }

        this._display = value;
    }

    show() {
        this.display = true;
    }

    hide() {
        this.display = false;
    }
}
