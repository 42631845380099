import useFetch from "@use/fetch";
import config from "@config/config";

export default function useData(url) {
    return {
        ...useFetch(url, {
            method: 'GET',
            headers: config.headers,
        })
    }
}
