<template>
    <div class="datepicker">
        <div class="datepicker__header">
            <div class="datepicker__date">{{month_text}} {{year}}</div>

            <div v-if="isSwitching()" class="datepicker__tool">
                <div class="datepicker__prev" @click="onPrev()">←</div>
                <div class="datepicker__next" @click="onNext()">→</div>
            </div>
        </div>

        <ul class="datepicker__week">
            <li class="datepicker__day-week"
                v-for="day in getWeekDays()"
                :key="day">{{day}}
            </li>
        </ul>

        <ul class="datepicker__days">
            <li class="datepicker__day datepicker__day_other-month"
                v-for="day in prevMonth"
                :key="day">{{getPrevMonthDay(day)}}
            </li>

            <li class="datepicker__day"
                v-for="day in days"
                :key="day"
                :class="{
                     'datepicker__day_weekend': isWeekend(day),
                     'datepicker__day_today': isToday(day),
                 }"
                @click="onSelect(day)">{{getDay(day)}}
            </li>

            <li class="datepicker__day datepicker__day_other-month"
                v-for="day in nextMonth"
                :key="day">{{day}}
            </li>
        </ul>
    </div>
</template>

<script>
    import {computed, reactive, ref} from 'vue'

    export default {
        name: "Datepicker",
        props: {
            dates: {
                type: Array,
            },
            switching: {
                type: Boolean,
                default: true
            },
            compact: {
                type: Boolean,
                default: false
            },
            lang: {
                type: String,
                default: 'rus',
            },
            maxWeeks: {
                type: Number,
                default: null
            },
        },
        setup: function (props, {emit}) {
            const config = {
                months: {
                    eng: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    rus: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    eng_abbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    rus_abbr: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                },
                week: {
                    eng: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
                    rus: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                },
            }

            const date = new Date();
            const today = date.getDate();
            let year = ref(date.getFullYear());
            let month = ref(date.getUTCMonth());

            const daysInWeek = 7;

            let selectedDate = ref(today);
            let selected = ref(null);

            let dates = reactive([]);
            get_dates(props.dates);

            function get_dates(value) {
                /*Получение и фильтрация данных*/
                value.filter(item => item.intervals && item.intervals.length)
                    .map((item, index) => {
                        dates.push({
                            "index": index,
                            "date": item.date,
                            "id": item.id,
                        })
                    })
            }

            function isWeekend(value) {
                let day = value + prev_month_week_day.value + start.value
                return day % 7 === 0 || day % 7 === 6
            }

            function isToday(value) {
                /*Определяет что {value} это сегодняшний день */
                return value + start.value === today
                    && month.value === date.getUTCMonth()
                    && year.value === date.getFullYear();
            }

            function onPrev() {
                month.value--
                if (month.value < 0) {
                    year.value--
                    month.value = 11
                }
                debug();
            }

            function onNext() {
                month.value++
                if (month.value > 11) {
                    year.value++
                    month.value = 0
                }
                debug();
            }

            function isSwitching() {
                return props.switching;
            }

            let month_text = computed(() => {
                /*Переводит название месяца*/
                return config.months[props.lang][month.value]
            })

            let first_day_month = computed(() => {
                /*Первый день месяца*/
                return new Date(year.value, month.value, 1)
            })

            let last_day_month = computed(() => {
                /*Последний день месяца*/
                return new Date(year.value, month.value + 1, 0).getDate()
            })

            /**
             * Prev
             * */

            let prev_month_week_day = computed(() => {
                /*Сколько дней из прошлого месяца
                 показывается в первой неделе текущего месяце*/
                const value = first_day_month.value.getDay() - 1
                return value >= 0 ? value : false
            })


            let prev_month_last_day = computed(() => {
                /*Последний день месяца*/
                return getDate(0).getDate()
            })

            let prevMonth = computed(() => {
                if (weeks.value) {
                    if (today > daysInWeek) {
                        return false
                    }
                }

                return prev_month_week_day.value
            })

            /**
             * Перебор, с какого числа начинается предидущий месяц,
             * попадающий в начало первой недели
             */
            function getPrevMonthDay(index) {
                return (prev_month_last_day.value - prev_month_week_day.value) + index
            }

            /***/
            let nextMonth = computed(() => {
                /**/
                return next_month_days.value
            })

            let next_month_days = computed(() => {
                /**/
                if (today + days.value < last_day_month.value) {
                    return false
                }

                let value = ((last_day_month.value + prev_month_week_day.value) % 7)
                return value ? daysInWeek - value : false
            })

            let weeks = computed(() => {
                return props.maxWeeks;
            })

            let days = computed(() => {
                if (weeks.value) {
                    if (today <= daysInWeek) {
                        return (weeks.value * daysInWeek) - (date.getDay())
                    } else {
                        return (weeks.value * daysInWeek)
                    }
                }

                return new Date(year.value, month.value + 1, 0).getDate()
            })

            /*Получает дату*/
            let start = computed(() => {
                if (weeks.value) {
                    if (today >= daysInWeek) {
                        return today - date.getDay()
                    }
                }

                return 0
            })

            function getDay(value) {
                return value + start.value
            }

            function getDate(value) {
                return new Date(year.value, month.value, value);
            }

            /*Выделение*/
            function onSelect(item) {
                selected.value = item;
                selectedDate.value = getDate(selected.value);

                emit('update:selects', selected.value);
                emit('onSelect', selectedDate.value);
            }

            function getWeekDays() {
                return config.week[props.lang]
            }

            function debug() {
                console.log('today:', today);
                console.log('day week:', date.getDay());
                console.log('first_day_month:', first_day_month.value.getDay());
                console.log('first_day_month:', first_day_month.value);
                console.log('prev_month_week_day:', prev_month_week_day.value);
                console.log('last_day_month:', last_day_month.value);
                console.log('prev_month_last_day:', prev_month_last_day.value);
                console.log('prev_month:', getPrevMonthDay(1));
                console.log('next_month_days:', next_month_days.value);
                console.log('-----------------------------------------------');
            }

            debug()
            return {
                getWeekDays,

                days,

                year,
                today,

                selected,

                month_text,

                first_day_month,

                prevMonth,
                prev_month_week_day,

                last_day_month,
                nextMonth,
                next_month_days,
                prev_month_last_day,

                isSwitching,

                onPrev,
                onNext,

                getPrevMonthDay,
                getDay,

                isWeekend,
                isToday,

                onSelect
            }
        }
    }
</script>

<style lang="less">
    .datepicker {
        --size: 40px;
        --day: 7;

        user-select: none;

        &__header {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &__date {
            font-size: var(--p);
            line-height: var(--size);
            text-align: center;
        }

        &__tool {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
        }

        &__day,
        &__prev,
        &__next {
            height: var(--size);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__prev,
        &__next {
            width: var(--size);
        }

        &__prev,
        &__next {
            color: gray;

            &:hover {
                color: black;
            }
        }

        &__week {
            font-size: var(--small);
            font-weight: bold;
            text-transform: uppercase;
            color: gray;

            margin: 10px 0;
        }

        &__week,
        &__days {
            display: grid;
            grid-template-columns: repeat(var(--day), 1fr);
            grid-template-rows: repeat(1, 1fr);
            text-align: center;

            outline: none;
            list-style-type: none;
        }

        &__day {
            font-size: var(--p);

            border: var(--border-size) solid var(--lt-gray);
            margin: 0 0 calc(var(--border-size) * -1) calc(var(--border-size) * -1);

            position: relative;
            transition: background .3s ease-in-out;

            &_other-month {
                background: var(--xl-gray);
            }

            &_weekend {
                color: darkblue;
            }

            &_today {
                font-weight: bold;
            }

            &_disabled {
                background: lightgray;
                color: gray;
            }

            &_select {
                background: lightcyan;
            }

            &_holidays {
                color: red;
            }

            &:not(.datepicker__day_other-month) {
                cursor: pointer;

                &:hover {
                    background: var(--secondary);
                    border: var(--border-size) solid var(--secondary);
                    z-index: 2;
                    cursor: pointer;
                    color: var(--white);

                    box-shadow: var(--shadow-base);
                }
            }
        }
    }
</style>
