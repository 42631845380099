<template>
    <ul class="nav nav_tabs">
        <li v-for="item in items"
            :key="item"
            class="nav__item">
            <a class="nav__link" :href="item.link">{{ item.name }}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "Departments",
        props:{
            items: {
                type: Object
            }
        }
    }
</script>

<style></style>
