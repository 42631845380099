<template>
    <div class="time-list">
        <div v-if="label" class="feature time-list__label">{{label}}</div>
        <ul v-if="list" class="time-list__list">
            <li class="time-list__item"
                v-for="item in times"
                :key="item"
                :class="{'time-list__item_active': item.select}"
                @click="onClick(item)">{{item.value}}
            </li>
        </ul>
    </div>
</template>

<script>
    import {reactive} from "vue";

    export default {
        name: "TimeList",
        props: {
            label: {
                type: String,
            },
            list: {
                type: Array
            }
        },
        setup(props, {emit}) {
            let times = reactive([])
            let select = reactive({})

            props.list
                .filter(item => item.free)
                .map((item, index) => {
                    times[index] = reactive({
                        value: item.start,
                        status: item.free
                    })
                    times[index].select = false
                })

            function onClick(item) {
                times.map(item => {
                    item.select = false
                })

                item.select = true
                emit('update:select', item)
            }

            return {
                onClick,
                times,
                select
            }
        }
    }
</script>

<style lang="less">
    .time-list {
        --size: 40px;
        --col: 4;

        &__label {
            margin-bottom: 1rem;
        }

        &__list {
            display: grid;
            grid-template-columns: repeat(var(--col), 1fr);
            grid-template-rows: repeat(1, 1fr);
            text-align: center;

            outline: none;
            list-style-type: none;
        }

        &__item {
            border: var(--border-size) solid var(--lt-gray);
            margin: 0 0 calc(var(--border-size) * -1) calc(var(--border-size) * -1);
            box-sizing: border-box;
            height: var(--size);
            line-height: var(--size);

            position: relative;
            transition: background .3s ease-in-out;

            &_active,
            &:hover {
                background: var(--secondary-dark);
                border: var(--border-size) solid var(--secondary-dark);
                z-index: 2;
                cursor: pointer;
                color: var(--white);

                box-shadow: var(--shadow-base);
            }

            &_active {
                background: var(--secondary-darker);
                border: var(--border-size) solid var(--secondary-darker);
            }
        }
    }
</style>
