// eslint-disable-next-line no-unused-vars
import {reactive, ref, watch} from 'vue'

export default function useField(field = {}) {
    const name = field.name
    const label = field.label
    const help = field.help
    const type = field.type
    const required = ref(field.required || false)

    let mask = field.mask

    let valid = ref(true)
    let value = ref(field.value)
    let status = ref(false)
    let empty = ref(true)


    const errors = reactive({})

    const reAssign = val => {
        valid.value = true;
        empty.value = value.value.length <= 0;

        Object.keys(field.validators ?? {}).map(name => {
            const validators = field.validators[name]
            const isValid = validators.validator(val)

            if (!isValid) {
                let msg = validators.message
                let obj = {
                    name: field.name,
                    label: field.label,
                    length: value.value.length,
                    value: validators.value,
                }

                errors[name] = {message: typeof msg === 'function' ? msg(obj) : msg}
            } else {
                delete errors[name]
            }

            if (!isValid) {
                valid.value = false
            }
        })
    }

    watch(value, reAssign)
    reAssign(value.value)

    return {
        value,
        valid,
        mask,
        label,
        name,
        required,
        type,
        errors,
        empty,
        help,
        status,
        blur: () => status.value = true,
        input: () => value
    }
}
