import Arrows from "@modules/ui/arrows";
import usePoints from "@modules/ui/points";
import useAuto from "@use/native/auto";

export default class SliderSimpleLeap {
    constructor(el, options) {
        this.tpl = {
            preloader: `<div class="preloader"></div>`,
        }

        const _default = {
            className: 'slide',
            delay: 4000,
            auto: false,
            points: true,
            arrows: true,
            items: null,
            onChange: () => {
            },
        }

        this.options = Object.assign({}, _default, options);

        this.step = 0;

        this.$el = el;

        this.$arrows = null;
        this.$points = null;

        if (this.options.items) {
            // this.$slides = [...this.$el.querySelector(this.options.className)];
            this.$slides = [...this.options.items];
        } else {
            this.$slides = [...this.$el.children];
        }

        this.total = this.$slides.length;

        if (this.total>1) {
            this.$el.classList.add('slider-simple');
            this.render();
        }
    }

    render() {
        this.addArrow();
        this.addPoint();

        for (const slide of this.$slides) {
            slide.classList.add('slider-simple__item');
        }

        this.change(this.step);
        if (this.options.auto) {
            useAuto(this.$el, () => {
                    this.auto();
                }, this.options.delay
            );
        }
    }

    auto() {
        let step = this.step + 1
        this.change(step);
    }

    change(step) {
        if (step > this.total - 1) step = 0
        if (step < 0) step = this.total - 1

        if (this.$slides[this.step]) {
            this.$slides[this.step].style.zIndex = 1;
            this.$slides[this.step].style.opacity = 0;
        }

        if (this.$points && this.total > 1) {
            this.$points.activation(step);
        }

        this.$slides[step].style.zIndex = 2;
        this.$slides[step].style.opacity = 1;
        this.step = step;
    }

    addPoint() {
        if (this.options.points && this.total > 1) {
            this.$points = new usePoints(this.$el);

            this.$points.render(this.total, this.step);
            this.$points.activation(this.step);

            this.$points.callback.onChange = (index) => {
                this.change(index);
            };
        }
    }

    addArrow() {
        if (this.options.arrows && this.total > 1) {
            const {$arrows, callbacks} = Arrows(this.$el);
            this.$arrows = $arrows;

            callbacks.onPrev = () => this.onPrev();
            callbacks.onNext = () => this.onNext();
        }
    }

    onPrev() {
        this.change(this.step - 1);
    }

    onNext() {
        this.change(this.step + 1)
    }
}
