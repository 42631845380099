<template>
    <div v-if="item" class="specialist">
        <div class="specialist__main specialist__main_compact">
            <figure v-if="item.thumbnail_url" class="specialist__image">
                <img :src="item.thumbnail_url" :alt="item.name">
            </figure>

            <div class="specialist__info">
                <h4 v-if="item.name" class="specialist__name" v-html="get_name()"></h4>

                <v-departments v-if="item.description"
                    class="specialist__departments"
                    :items="item.departments"></v-departments>

                <v-specialty
                    v-if="item.specialty"
                    class="specialist__specialty"
                    :items="item.specialty"></v-specialty>
                <div v-if="item.category" class="specialist__category">{{item.category.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Specialty from "@components/specialist/Specialty";
    import Departments from "@components/specialist/Departments";

    export default {
        name: "Specialist",
        components: {
            "v-departments": Departments,
            "v-specialty": Specialty,
        },
        props: {
            item: {
                type: Object,
                default: null
            },
        },
        setup(props) {
            function get_name() {
                return props.item ? props.item.name.split(' ').join('<br/>'): null
            }

            return {
                get_name
            }
        }
    }
</script>

<style></style>
