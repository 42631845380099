export default function useDebug() {
    function debug(values) {
        Object.keys(values ?? {}).map((value) => {
            console.log(`${value} - ${values[value]}`);
        })
        console.log('----------------------------');
    }

    return {debug}
}
