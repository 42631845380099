import config from "@config/config"
import useTemplate from "@use/native/template";
import {letter} from "@utils/string";

import {Loader} from "@googlemaps/js-api-loader"

const API_KEY = config.google.map_api_key;
// const MAP_ID = config.google.map_id;

let google = null;

export default class useGoogleMaps {
    /**
     * Добавляет карту в el
     *
     * Для работы нужно установить
     * npm install @googlemaps/js-api-loader
     * или добавив
     * <script src="https://unpkg.com/@googlemaps/js-api-loader@1.0.0/dist/index.min.js"></script>
     *
     * @param el
     * @param options
     */
    constructor(el, options) {
        const _default = {
            zoom: 13,
            scroll: false,
            lat: null, lng: null,
            defaultUI: true,
            center: false,
            icon: null,
            markers: [],
            label: {
                status: false,
                style: {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '18px'
                }
            },
            flightPath: {
                path: [],
                geodesic: true,
                strokeColor: '#000000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            }
        }

        this.tpl = {
            features: useTemplate`<div class="features ${'cls'}">
                                     <div class="features__title">${'title'}</div>
                                     <div class="features__list">${'content'}</div>
                                 </div>`,
            feature: useTemplate`<div class="feature ${'cls'}">
                                    <div class="feature__label">${'label'}</div>
                                    <div class="feature__value">${'value'}</div>
                                </div>`,
            title: useTemplate`<div class="title ${'cls'}">${'text'}</div>`,
            more: useTemplate`<a href="${'url'}" class="more">${'text'}</a>`,
            latter: useTemplate`<i class="letter">${'text'}</i>`,
        }

        this.options = Object.assign({}, _default, options);

        this.id = null;
        this.infowindowopen = null;
        this.image = '';
        this.markers = [];

        const mapConfig = {
            center: {lat: -34.397, lng: 150.644},
            zoom: 8,
            scrollwheel: this.options.scroll
        }

        const loader = new Loader({
            apiKey: API_KEY,
            version: "weekly",
        });

        loader.load().then(() => {
            this.google = window.google;
            this.bounds = new this.google.maps.LatLngBounds();
            this.map = new this.google.maps.Map(el, mapConfig);
            this.render();
        });
    }

    render() {
        this.addPolyline();

        if (this.options.markers && this.options.markers.length) {
            let markersList = this.options.markers;
            let count = 0;

            for (const marker of markersList) {
                this.addMarker(marker, count);
                count++
            }
        } else {
            let marker = {
                lat: this.options.lat,
                lng: this.options.lng,
                icon: this.options.icon,
                content: this.options.content
            };
            this.addMarker(marker);
        }

        this.center();
    }

    addPolyline(path = this.options.flightPath.path) {
        if (path) {
            let flightPath = new this.google.maps.Polyline({path: path});
            flightPath.setMap(this.map);
        }
    }

    addMarker(item, id = null) {
        let obj = {
            position: {
                lat: item.lat,
                lng: item.lng
            },
            map: this.map,
            icon: item.icon ?? this.options.icon,
            title: item.name,
            zIndex: item.depth
        }

        if (this.options.label.status) {
            obj['label'] = this.getLabel(item, id, this.options.label);
        }

        let marker = new this.google.maps.Marker(obj);

        if (item.content && item.content !== {}) {
            let content = this.getContentTemplate(item.content);

            let infowindow = new this.google.maps.InfoWindow({
                content: content,
                disableAutoPan: false
            });

            this.map.addListener('click', () => {
                infowindow.close();
            });

            marker.addListener('click', () => {
                if (this.infowindowopen) {
                    this.infowindowopen.close();
                }

                infowindow.open(this.map, marker);
                this.infowindowopen = infowindow;
            });

            this.bounds.extend(marker.getPosition());
        }

        this.markers.push(marker);
    }

    getLabel(item, id = null, label = null) {
        let _label = label.style;
        _label.text = item.id ?
            item.id.toString() :
            letter('ABCDEFGHIKLMNOPQRSTVXYZ', id);
        return _label;
    }

    getContentTemplate(content) {
        let features = ''
        Object.keys(content ?? {}).map((name) => {
            let obj = Object.assign({}, content[name], {cls: 'map__feature'})
            features += this.tpl.feature(obj)
        })

        return this.tpl.features({title: content.title, cls: 'map__info-window', content: features})
    }

    center() {
        /**
         * Центрует относительно всех маркеров
         * есле нет, относительно координаты
         */
        if (this.options.center) {
            this.map.fitBounds(this.bounds);
            this.map.setZoom(this.map.getZoom());

            if (this.options.zoom) {
                console.log(this.options);
                this.map.setZoom(this.options.zoom);
            } else {
                if (this.map.getZoom() > 13) {
                    this.map.setZoom(13);
                }
            }
        } else {
            this.map.setCenter(new this.google.maps.LatLng(this.options.lat, this.options.lng));
            this.map.setZoom(this.options.zoom);
        }
    }

    focusHandler(id) {
        google.maps.event.trigger(this.markers[id], 'click');
    }
}
