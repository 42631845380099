import {reactive, toRefs} from 'vue'

export default function useFetch(url, options) {
    const state = reactive({
        error: null,
        fetching: true
    })

    // const checkForError = response => {
    //     if (!response.ok) throw Error(response.statusText);
    //     return response.json();
    // };

    const fetchData = async () => {
        try {
            const res = await fetch(url, options)
            return await res.json()
        } catch (errors) {
            state.error = errors
        } finally {
            state.fetching = false
        }
    }

    return {...toRefs(state), fetchData}
}
