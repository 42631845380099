export default function useAuto(target, callbacks, delay = 4000) {
    let autoIntervalId = null;

    target.addEventListener('mouseenter', () => {
        if (autoIntervalId) {
            clearInterval(autoIntervalId);
        }
    })

    target.addEventListener('mouseleave', () => {
        autoIntervalId = setInterval(() => {
            callbacks();
        }, delay);
    })

    autoIntervalId = setInterval(() => {
        callbacks();
    }, delay);
}
