export default function useDrag(target, options) {
    const _defaults = {
        dist: 100,
        direction: 'horizontal', // horizontal, vertical
        init: true
    }

    const _options = Object.assign({}, _defaults, options);

    let drag = false;

    let start_x = 0;
    let start_y = 0;

    let mouse_offset_x = 0;
    let mouse_offset_y = 0;

    const distance = _options.dist;
    const touch = 'ontouchstart' in window;

    const callbacks = {
        onSwipeLeft: function () {
        },
        onSwipeRight: function () {
        },
        onSwipeUp: function () {
        },
        onSwipeDown: function () {
        },

        onMouseDown: function () {
        },
        onMouseMove: function () {
        },
        onMouseUp: function () {
        },
    }

    if (_options.init) {
        dragInit();
    }

    function dragInit() {
        if (target) {
            target.ondragstart = () => false;
            if (touch) {
                target.addEventListener('touchstart', onMouseDown);
            } else {
                target.addEventListener('mousedown', onMouseDown);
            }
        }
    }

    function dragRemove() {
        if (target) {
            if (touch) {
                target.removeEventListener('touchstart', onMouseDown);
            } else {
                target.removeEventListener('mousedown', onMouseDown);
            }
        }
    }

    function eventCheck(e) {
        if (e.touches && e.touches.length) {
            e = e.touches[0];
        } else if (e.changedTouches && e.changedTouches.length) {
            e = e.changedTouches[0];
        }
        return e;
    }

    function onMouseDown(e) {
        e = eventCheck(e);

        drag = true;
        start_x = e.clientX - target.offsetLeft;
        start_y = e.clientY - target.offsetTop;

        if (touch) {
            target.addEventListener('touchmove', onMouseMove);
            target.addEventListener('touchend', onMouseUp);
            window.addEventListener('touchend', onMouseUp);
        } else {
            target.addEventListener('mousemove', onMouseMove);
            target.addEventListener('mouseup', onMouseUp);
            target.addEventListener('mouseleave', onMouseUp);
            window.addEventListener('mouseup', onMouseUp);
        }

        callbacks.onMouseDown(drag);
    }

    function onMouseMove(e) {
        e = eventCheck(e)
        let elemBelow = document.elementFromPoint(e.clientX, e.clientY);
        if (!elemBelow) return;

        let offset_position_x = e.clientX - target.offsetLeft;
        let offset_position_y = e.clientY - target.offsetTop;

        if (drag) {
            mouse_offset_x = offset_position_x - start_x;
            mouse_offset_y = offset_position_y - start_y;

            callbacks.onMouseMove(mouse_offset_x, mouse_offset_y, drag)
        }
    }

    function onMouseUp() {
        drag = false;

        if (!drag) {
            if (mouse_offset_x > distance) {
                callbacks.onSwipeRight()
            } else if (mouse_offset_x < -(distance)) {
                callbacks.onSwipeLeft()
            }

            if (mouse_offset_y > distance) {
                callbacks.onSwipeDown()
            } else if (mouse_offset_y < -(distance)) {
                callbacks.onSwipeUp()
            }

            callbacks.onMouseUp(drag);
            mouse_offset_x = 0;
            mouse_offset_y = 0;
        }

        if (touch) {
            target.removeEventListener('touchmove', onMouseMove);
            target.removeEventListener('touchend', onMouseUp);
            window.removeEventListener('touchend', onMouseUp);
        } else {
            target.removeEventListener('mousemove', onMouseMove);
            target.removeEventListener('mouseup', onMouseUp);
            target.removeEventListener('mouseleave', onMouseUp);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }

    return {
        drag,
        callbacks,

        start_x,
        start_y,

        mouse_offset_x,
        mouse_offset_y,

        dragInit,
        dragRemove,
    }
}
