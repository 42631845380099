export const required = val => !!val;
export const minLength = num => val => val.length >= num || val.length === 0;
export const maxLength = num => val => val.length <= num;

export const pattern = reg => val => reg.test(val);
export const email = val => patterns.email.test(val) || val.length === 0;
export const name = val => patterns.name.test(val) || val.length === 0;
export const phone = val => patterns.phone.test(val) || val.length === 0;

export const patterns = {
    text: /^[a-zA-Zа-яА-Я]{4,12}$/,
    email: /\S+@\S+\.\S+/,
    phone: /^[0-9]{7,12}$/,
}
