<template>
    <div class="thanks">
        <div class="thanks__title">{{title}}</div>
        <div class="thanks__description">{{description}}</div>
        <div class="thanks__image">{{image}}</div>
    </div>
</template>

<script>
    export default {
        name: "Thanks",
        props: {
            title: {
                type: String
            },
            description: {
                type: String,
                required: false
            },
            image: {
                type: String,
                required: false
            },
        }
    }
</script>

<style></style>
