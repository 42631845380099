import useSearch from "@use/data/core/search";
import useSearchHistory from "@use/data/core/search_history";

const search = {
    namespaced: true,
    state: () => ({
        query: '',
        history: [],
        result: [],
        progress: false,
    }),
    getters: {
        getQuery: (state) => {
            return state.query
        },
        getResult: (state) => {
            return state.result
        },
        getHistory: (state) => {
            return state.history
        },
        getProgress: (state) => {
            return state.progress
        }
    },
    mutations: {
        SET_RESULT(state, payload) {
            if (state.result !== payload) {
                state.result = payload.result;
            }
        },
        SET_HISTORY(state, payload) {
            if (state.history !== payload) {
                state.history = payload
            }
        },
        START_PROGRESS(state) {
            state.progress = true
        },
        END_PROGRESS(state) {
            state.progress = false
        },
    },
    actions: {
        clearResult(context) {
            context.commit('SET_RESULT', []);
        },
        async getResult(context, payload) {
            context.commit('START_PROGRESS');
            const {fetchData} = useSearch(payload);
            let data = await fetchData();
            context.commit('SET_RESULT', data);
            context.commit('END_PROGRESS');
        },
        async getHistory(context) {
            const {fetchData} = useSearchHistory();
            let data = await fetchData();
            context.commit('SET_HISTORY', data);
        }
    },
}


export default search
