export default class usePoints {
    constructor(el, options) {
        this.tpl = {
            points: `<ul class="points"/>`,
            point: `<li class="points__item"/>`,
        }

        const _default = {}
        this.options = Object.assign({}, _default, options);

        this.$el = el;
        this.$points = null;

        this.step = 0;
        this.quantity = 0;
        this.active = null;

        this.$el.insertAdjacentHTML('afterbegin', this.tpl.points);
        this.$points = this.$el.querySelector('.points');

        this.callback = {
            onChange: function () {
            },
        }
    }

    show() {
        this.$points.style.display = 'flex';
    }

    hide() {
        this.$points.style.display = 'none';
    }

    render(quantity, step) {
        if (quantity > 1) {
            if (this.quantity !== quantity) {
                if (quantity > this.quantity) {
                    for (let i = 0; i < quantity; i++) {
                        this.$points.insertAdjacentHTML('beforeend', this.tpl.point);
                    }
                }
                this.quantity = quantity;
            }
        }

        this.getPoints();
        this.activation(step);
    }

    getPoints() {
        this.$items = [...this.$points.querySelectorAll('.points__item')];

        if (this.$items.length) {
            for (const i in this.$items) {
                const index = parseInt(i),
                    item = this.$items[index];

                item.addEventListener('click', () => {
                    this.change(index);
                })
            }
        }
    }

    activation(index) {
        if (this.$items.indexOf(this.$items[index]) !== -1) {
            if (this.active !== null) this.$items[this.active].classList.remove('points__item_active');
            this.$items[index].classList.add('points__item_active');
            this.active = index;
        }
    }

    change(step) {
        this.step = step;
        this.activation(step)
        this.callback.onChange(step);
    }
}
