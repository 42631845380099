<template>
    <div class="close"
         @click="onClick">

        <div class="close__label"
             v-if="$slots.default || label">
            <slot name="default">{{label}}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Close",
        props: {
            label: {
                type: String,
                default: null
            }
        },
        setup(props, {emit}) {
            function onClick() {
                emit('onClick')
            }

            return {
                onClick
            }
        }
    }
</script>

<style></style>
