import {reactive} from "vue";
import useData from "@use/data/services/services";


const navigation = {
    namespaced: true,
    state: () => ({
        show: false,
        list: [],
        loaded: true
    }),
    getters: {
        isShow: (state) => {
            return state.show
        },
        getItems: state => payload => {
            return state.list ? state.list[payload] : null
        },
    },
    mutations: {
        SHOW(state, payload) {
            state.show = payload
        },
        SET_ITEMS(state, payload) {
            if (payload?.id && payload?.data && !state.list[payload.id]) {
                findActivePath(payload.data);
                state.list[payload.id] = payload.data
            }
        }
    },
    actions: {
        show({commit}, payload) {
            commit('SHOW', payload);
        },
        async getItems({commit}, payload) {
            const {fetchData} = useData(payload?.url);
            let data = await fetchData();

            if (data) {
                commit('SET_ITEMS', {
                    data: data,
                    id: payload.id
                });
            }
        }
    },
    modules: {}
}


export default navigation

function findActivePath(nodes) {
    let path = window.location.pathname,
        pathList = path.split('/'),
        rootLevel = `/${pathList[1]}`

    const list = reactive([]);

    function findItem(node) {
        if (node.children) {
            for (const item of node.children) {
                if (item.children) {
                    if (item.url === path) {
                        list.push(item)
                    }
                    findItem(item)
                } else {
                    if (item.url === path) {
                        list.push(node)
                        list.push(item)
                    }
                }
            }
        }
    }

    for (const item of nodes) {
        if (item.url === rootLevel) {
            list.push(item)
            findItem(item);
            break;
        } else {
            findItem(item);
        }
    }

    for (const item of list) {
        item.active = true;
    }
}


