<template>
    <div class="answer"
         :class="{'answer_check':item.check}"
         @click="changeHandler">
        <div class="answer__text"><span>{{id}}</span> {{item.answer}}</div>
    </div>
</template>

<script>
    import {ref} from "vue";

    export default {
        name: "Answer",
        props: {
            id: Number,
            item: Object,
        },
        setup(props, {emit}) {
            const value = ref(props.item.check);

            function changeHandler() {
                emit('update:value', !props.item.check);
            }

            return {
                value,
                changeHandler
            }
        }
    }
</script>

<style scoped lang="less">
    .answer {
        padding: 15px;
        font-size: 13px;
        margin-bottom: 10px;
        border: 1px solid var(--ex-lt-gray);
        border-radius: 5px;
        transition: all .3s ease;
        cursor: pointer;

        &_check {
            background: var(--secondary-light);
            color: var(--black);
        }

        &__text {
            user-select: none;

            span {
                font-weight: bold;
                color: var(--gray);
                margin-right: .5rem;
            }
        }

        &:hover {
            /*background: var(--secondary-lighter);*/
            box-shadow: var(--shadow-base);
        }
    }
</style>
