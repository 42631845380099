import useFetch from "@use/fetch";
import config from "@config/config";

export default function useService(id) {
    return {
        ...useFetch(`/api/v1/service/${id}`, {
            method: 'GET',
            headers: config.headers,
        })
    }
}
