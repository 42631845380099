<template>
    <div class="search" v-click-outside="onBlur">
        <div class="search__field">
            <div class="search__btn"></div>
            <div class="search__form">
                <input class="search__input" v-model="question" type="text"
                       @input="onChange($event)"
                       @focus="onFocus"
                >
                <v-button class="search__submit" @onClick="onClick">Найти</v-button>
                <div v-if="progress && focused" class="search__progress preloader"></div>
            </div>
        </div>

        <div v-if="warning" class="search__warning" v-html="warning"></div>

        <div v-if="!drop || ((!isEmpty || !isEmptyPopular) && focused)"
             class="search__results"
             :class="{'search__results_drop': drop}"
        >
            <ul v-if="!isEmpty" class="search__group">
                <li class="search__result"
                    v-for="(item, index) in results"
                    :class="{'search__result_select': index === select}"
                    :key="item"
                >
                    <a class="search__link" :href="item.link">{{item.name}}</a>
                </li>
            </ul>

            <div v-if="!isEmptyPopular" class="popular">
                <div class="popular__label">Популярные запросы</div>
                <ul class="search__groups">
                    <li class="search__result"
                        v-for="item in popular"
                        :key="item"
                        @click="setPopular(item.question)"
                    >
                        {{item.question}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed, ref} from "vue";

    import Button from "@components/ui/forms/Button";
    import {useStore} from "vuex";

    export default {
        name: "Search",
        components: {
            "v-button": Button,
        },
        props: {
            q: {
                type: String,
                default: null,
            },
            min: {
                type: Number,
                default: 3,
            },
            drop: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, {emit}) {
            const store = useStore();
            let question = ref(props.q),
                warning = ref(''),
                focused = ref(false),
                select = ref(-1);

            let progress = computed(() => store.getters['search/getProgress'],);
            let results = computed(() => store.getters['search/getResult'],);
            let popular = computed(() => store.getters['search/getHistory'],);

            let isEmpty = computed(() => {
                if(results.value === undefined || results.value === null){
                    return true
                }else{
                   return Object.keys(results.value).length === 0;
                }
            });

            let isEmptyPopular = computed(() => {
                if(popular.value === undefined || popular.value === null){
                    return true
                }else{
                    return Object.keys(popular.value).length === 0;
                }
            });

            let intervalId = ref(null);

            document.addEventListener("keydown", onKeyDown);
            function onKeyDown(e) {
                if(!isEmpty.value){
                    if (e.keyCode === 40) {
                        // console.log('Down');
                        select.value = select.value + 1 < results.value.length ? select.value + 1: 0;
                    }else if(e.keyCode === 38){
                        // console.log('Up');
                        select.value = select.value - 1 >= 0 ? select.value - 1: results.value.length - 1;
                    }else if(e.keyCode === 13 && select.value){
                        if(results.value[select.value]){
                            window.location.href = results.value[select.value].link;
                        }
                        // console.log('Enter', select);
                    }
                }
            }

            function onClick() {
                if (question.value.length >= props.min) {
                    window.location.href = `/search/?q=${question.value}`;
                    warning.value = ''
                } else {
                    warning.value = `Должно быть больше <span>${props.min}</span> символов`
                }

                emit('onClick');
            }

            function onBlur() {
                focused.value=false;
            }

            function onFocus() {
                focused.value=true;
                warning.value = ''
                if (question.value && question.value.length && isEmpty.value && question.value.length >= props.min){
                    store.dispatch('search/getResult', question.value);
                    if(popular.value){
                        store.dispatch('search/getHistory');
                    }
                }
            }

            function setPopular(value) {
                question.value = value;
                store.dispatch('search/getResult', question.value);
            }

            function onChange(e) {
                if (intervalId.value) {
                    clearTimeout(intervalId.value);
                    intervalId.value = null;
                }

                intervalId.value = setTimeout(() => {
                    if (question.value.length >= props.min) {
                        store.dispatch('search/getResult', question.value);
                    } else {
                        store.dispatch('search/clearResult');
                    }

                    emit('onChange', e.target.value);
                }, 250)
            }

            return {
                results,
                popular,
                focused,
                question,
                warning,
                progress,
                select,
                isEmpty,
                isEmptyPopular,
                setPopular,
                onFocus,
                onBlur,
                onClick,
                onChange
            }
        }
    }
</script>

<style></style>
