import useFetch from "@use/fetch";
import config from "@config/config";

export default function useQuizCreate(payload) {
    return {
        ...useFetch('/api/v1/quiz/create/', {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify(payload),
        })
    }
}
