import useFetch from "@use/fetch";
import config from "@config/config";

export default function useQuiz(payload) {
    return {
        ...useFetch(`/api/v1/quiz/${payload}`, {
            method: 'GET',
            headers: config.headers,
        })
    }
}
