<template>
    <div v-if="offer" class="feedback__price feature">
        <div class="feature__label">{{offer.name}}</div>
        <div class="price feature__value" v-html="offer.price"></div>
    </div>

    <div v-if="specialist" class="feedback__price">
        <div class="price feature">
            <div class="feature__label">Консультация</div>
            <span class="feature__value" v-if="specialist.cost">
                    <span v-if="specialist.cost_from == true">от</span>
                    <strong>{{ specialist.cost }}</strong>
                    <span>₽</span>
                </span>
            <strong v-else>Бесплатно</strong>
        </div>

        <div v-if="specialist.includes" class="includes feature">
            <div class="feature__label">В консультацию входит:</div>
            <div class="feature__value">{{specialist.includes}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Price",
        props: {
            specialist: {
                type: Object,
                default: null
            },
            offer: {
                type: Object,
                default: null
            }
        }
    }
</script>

<style></style>
